import React, {useContext, useEffect, useState} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
    checkin_report_url,
    defDateFormat,
    empty,
    req_acc_project,
    req_branch_per_cust,
    req_checkin_merchandising,
    req_checkin_sched,
    req_cust,
    req_merchandiser,
    req_module_setting,
    req_region,
    req_salesman,
    reset,
	req_checkin_unsched,
	req_checkin_import,
	req_checkin_export,
	req_delete_file
} from '../../Helper/Constants';
import {deepPurple, green, red, blue, grey} from '@material-ui/core/colors';
import {usePaperStyles} from '../../Styles/StylePaper';
import {useCommonStyles} from '../../Styles';
import moment from 'moment';
import AppContext from '../../Services/AppContext';
import {useSnackbar} from 'notistack';
import PersistantData from '../../Services/PersistantData';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import format from 'date-fns/format';
import {
	Box,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Tab,
    Tabs,
    Typography,
	makeStyles
} from '@material-ui/core';
import {
    Add as AddIcon,
    ClearAll,
    FilterList as FilterListIcon,
    FindInPage,
    KeyboardArrowRightRounded,
    Person as PersonIcon
} from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import CheckinDetails from '../../Components/CheckinDetails';
import _ from 'lodash';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleIcon from '@material-ui/icons/Schedule';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import VirtualList from '../../Components/VirtualList';
import BusinessIcon from '@material-ui/icons/Business';
import {de, getSetting} from '../../Helper/Helper';
import ListView from '../../Components/ListView';
import Fab from '@material-ui/core/Fab';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AnyDialog from '../../Components/AnyDialog';
import AddAppointmentMerchandiser from '../Checkin/AddAppointmentMerchandiser';
import AddVisitSched from '../Checkin/AddVisitSched';
import AppointmentView from '../Checkin/AppointmentView';
import ColorRandomizer from '../../Helper/ColorRandomizer';
import WarningIcon from '@material-ui/icons/Warning';
import FixedList from '../../Components/FixedList';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import MapIcon from '@material-ui/icons/Map';
import SendSMS from "../../Components/SendSMS";
import GenerateView from "../../Components/GenerateView";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { DropzoneArea } from 'material-ui-dropzone';

const ClearButton = withStyles(theme => ({
	root: {
		borderColor: red[500]
	}
}))(Button);

const useStyles = makeStyles((theme) => ({
	dropzoneText: {
		color: grey[600]
	}
}));

const schedHeadCell = [
	{
		name:"sched_code",
		label:"Code",
		options:{
			display: true
		}
	},
	{
		name:"name",
		label:"Assignee",
		options:{
			display: true
		}
	},
	{
		name:"cust_company_name",
		label:"Customer",
		options:{
			display: true
		}
	},
	{
		name:"sched_datetime",
		label:"Appointment At",
		options:{
			display: true,
			filter:false,
			customBodyRender : (value, tableMeta, updateValue) => {
				return(
					moment(value,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
				)
			}
		}
	},
	{
		name:"sched_note",
		label:"Note",
		options:{
			display: true
		}
	},
	{
		name:"site_in_charge",
		label:"In-charge",
		options:{
			display: true,
			filter:false
		}
	},
	{
		name:"site_in_charge_contact",
		label:"In-charge Contact",
		options:{
			display: false,
			filter:false
		}
	},
	{
		name:"sched_status",
		label:"Appointment Status",
		options:{
			display:true,
			customBodyRender : (value, tableMeta, updateValue) => {
				switch(value){
					case "0" : return (<Chip size="small" icon={<ScheduleIcon/>} label="Pending"/>); break;
					case "1" : return (<Chip size="small" icon={<DoneIcon/>} label="Completed"/>); break;
					case "2" : return (<Chip size="small" icon={<CloseIcon/>} label="Reject"/>); break;
					default : return (<Chip size="small" icon={<ScheduleIcon/>} label="Pending"/>); break;
				}
			}
		}
	},
	{
		name:"in_progress",
		label:"Progress",
		options:{
			display:true,
			customBodyRender : (value, tableMeta, updateValue) => {
				switch(value){
					case "0" : return (<Chip size="small" icon={<WarningIcon/>} label="Pending"/>); break;
					case "1" : return (<Chip size="small" icon={<ScheduleIcon color={'primary'}/>} label="Running"/>); break;
					case "2" : return (<Chip size="small" icon={<DoneIcon/>} label="Done"/>); break;
					default : return (<Chip size="small" icon={<ScheduleIcon/>} label="Pending"/>); break;
				}
			}
		}
	},
	{
		name:"project_no",
		label:"Projects",
		options:{
			display: true,
			filter:false,
			customBodyRender : (value, tableMeta, updateValue) => {
				if(JString(value).isJson()){
					const json = JString(value).toJson();
					return(
						<div>
							{
								json.map((item,index)=>
									<Chip
										style={{marginBottom:2}}
										size="small"
										label={item.project_code}
										key={'chip-project-'+index}/>
								)
							}
						</div>
					)
				}
			}
		}
	},
	{
		name:"tags",
		label:"-",
		options:{
			display: true,
			filter:false,
			customBodyRender : (value, tableMeta, updateValue) => {
				if(JString(value).isJson()){
					const json = JString(value).toJson();
					const colors = ColorRandomizer({
						luminosity: 'bright',
						hue: 'blue',
						count: Object.keys(json).length
					});
					return(
						<div>
							{
								Object.keys(json).map((key,index)=>
									<div key={String(index)+'--div'}>
										<span style={{color:'grey', fontSize:14}}>{String(key).toUpperCase()}</span>
										<div style={{display:'flex',flexDirection:'row'}}>
											{
												json[key].map((item,nidx)=>
													<Chip
														style={{backgroundColor:colors[index],color:'white'}}
														size="small"
														label={item}
														key={'chip'+nidx}/>
												)
											}
										</div>
									</div>)
							}
						</div>
					)
				}
			}
		}
	},
	{
		name:"updated_at",
		label:"Updated At",
		options:{
			display: true,
			filter:false,
			customBodyRender : (value, tableMeta, updateValue) => {
				return(
					moment(value,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm A')
				)
			}
		}
	},
	{
		name:"created_by",
		label:"Created By",
		options:{
			display: true,
			filter:false
		}
	}
];

const statusList = [
	{
		name: 'Pending',
		value: '0'
	},
	{
		name: 'Running',
		value: '1'
	},
	{
		name: 'Complete',
		value: '2'
	},
	{
		name: 'Missed',
		value: '3'
	}
];

const appointmentHeadCell = [
	{
		name:"staff_code",
		label:"Agent"
	},
	{
		name:"num_of_appointments",
		label:"Visit Schedule",
		options:{
			display: true,
			filter:false,
			customBodyRender : (value, tableMeta, updateValue) =>{
				return(
					<Typography variant={'caption'} color={'textPrimary'}>
						{ value } Places
					</Typography>
				)
			}
		}
	},
	{
		name:"start_date",
		label:"Start Date",
		options:{
			display: true,
			filter:false,
			customBodyRender : (value, tableMeta, updateValue) =>{
				return(
					moment(value,'YYYY-MM-DD').format('DD/MM/YYYY')
				)
			}
		}
	},
	{
		name:"end_date",
		label:"End Date",
		options:{
			display: true,
			filter:false,
			customBodyRender : (value, tableMeta, updateValue) =>{
				return(
					moment(value,'YYYY-MM-DD').format('DD/MM/YYYY')
				)
			}
		}
	}
];


const makebranchname = (obj) =>{
	return JString(obj.branch_name)
			.replaceInfinity('[\(\)]','')
			.replaceInfinity(obj.branch_code,'')
			.str.trim()
		||
		'-';
}
const makeaddress = (obj) =>{
	return `${obj.billing_address1} ${obj.billing_address2} ${obj.billing_address3} ${obj.billing_address4}`;
}
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
export default function ManageSched(){
	const classes = useStyles();
	const paperStyle = usePaperStyles();
	const commonStyles = useCommonStyles();

	const [ gState, setGState ] = useContext(AppContext);
	const [ isSearching, setSearching ] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [ configLoading, setConfigLoading ] = useState(true);

	const [ filterOpen, showFilter ] = useState(false);
	const [ addnewSched, showNewSched ] = useState(false);

	const [ regionList, setRegionList] = useState([]);
	const [ regionView, setRegionView] = useState(false);
	const [ regionSelected, setRegionSelected ] = useState(empty);

	const [ tchList, setTchList ] = useState([]);
	const [ mchList, setMchList ] = useState([]);
	const [ spList, setSpList ] = useState([]);
	const [ spValue, setSpValue ] = useState(empty);
	const [ spView, setSpView ] = useState(false);
	const [ statusView, setStatusView ] = useState(false);
	const [ status, setStatus ] = useState(empty);

	const [ rawCustomers, setRawCustomers ] = useState([]);

	const [ branchPercustomer, setBranchPerCustomer ] = useState({});
	const [ csList, setCsList ] = useState([]);
	const [ csOutstanding, setCsOutstanding ] = useState({});
	const [ csValue, setCsValue ] = useState('');
	const [ isCustomerView, showCustomerView ] = useState(false);

	const [ projectData, setProjectData ] = useState([]);
	const [ scheduleData, setScheduleData ] = useState([]);

	const [ checkinDataToview, setCheckinDataToView ] = useState(undefined);
	const [ axiosApiConfig, setAxiosApiConfig ] = useState({});

	const [ appointmentData, setAppointmentData ] = useState({});
	const [ unschedAppointmentData, setUnschedAppointmentData ] = useState({});

	const [ selectedMch, setSelectedMch ] = useState('');
	const [ schedTabPosition, setSchedTabPosition ] = useState(0);

	const [ openDateDoc, setOpenDateDoc ] = useState(false);
	const [ docDateRange, setDocDateRange ] = useState({
		startDate: new Date(currentYear, currentMonth, 1),
		endDate: new Date(currentYear, currentMonth + 1, 0)
	});

	const [ customModule, setCustomModule ] = useState({});
	const [ addVisitSchedRules, setAddVisitSchedRules ] = useState({});
	const [ appointmentGroups, setAppointmentGroups ] = useState([]);

	const [ checkinConfig, setCheckinConfig ] = useState('');

	const [ editSched, setEditSched ] = useState({ open:false, editData:{} });

	const [ smsView, setSMSView ] = useState(false);
	const [ schedCode, setSchedCode] = useState([]);

	const [ printView, setPrintView ] = useState(false);

	const [calenderDate, setCalenderDate] = useState(null);

	const [filterStatus, setFilterStatus] = useState('reset');

	const [exportOnView, setExportOnView] = useState(false);
	const [tmpltCalenderOnView, setTmpltCalanderOnView] = useState(false);
	const [tmpltCalanderDateRange, setTmpltCalanderDateRange] = useState({});
	const [tmpltSPOnView, setTmpltSPOnView] = useState(false);
	const [tmpltSPValue, setTmpltSPValue] = useState(empty);
	const [importOnView, setImportOnView] = useState(false);
	const [fileData, setFileData] = useState(null);
	const [loading, setLoading] = useState(false);

	const singleton = PersistantData.getInstance();
	var { translation: language, client } = gState;
	if(typeof(language) === 'undefined'){
		language = singleton.getlang()
	}

	const typeOptions = [
		{ name: 'Basic Version', value: 'simply' },
		{ name: 'Detailed Version', value: 'normal' }
	]

	const dateMenuOptions = { name: language.doc_date, value: reset };

	useEffect(()=>{
		if(JString(gState.client).isValid()){
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			loadRegion(axiosGetConfig)
			loadCustomerList(axiosGetConfig);
			loadSalesmanList(axiosGetConfig);
			loadMerchandiserList(axiosGetConfig);
			loadCheckConfig(axiosGetConfig);
			loadBranchPerCustomer(axiosGetConfig);
			loadProjectData(axiosGetConfig);
			setAxiosApiConfig(axiosGetConfig);
			statusList.push({name: language.status, value: reset});
		}
	},[gState.client]);

	useEffect(()=>{
		customFilter();
	},[appointmentData,unschedAppointmentData]);

	const decideAndLoad = (type = checkinConfig,axiosConfig = axiosApiConfig) =>{
		if(type==='merchandising'){
			de('loading appointment')
			loadAppointmentData({}, axiosConfig);
			// loadAppointmentGroup(axiosConfig);
			loadUnschedData(axiosConfig);
		}else{
			loadSchedData(axiosConfig);
		}
	}

	const loadProjectData = (axiosGetConfig) =>{
		API_Service.get(req_acc_project,axiosGetConfig).then(({data})=>{
			const { result, extraData } = data;
			if(result){
				setProjectData(extraData);
			}
		});
	}

	const loadCheckConfig = (axiosGetConfig) =>{
		API_Service.get(req_module_setting,axiosGetConfig).then(({ data })=>{
			const { result, extraData } = data;
			if(result == 1){
				const config = getSetting(extraData,'checkin',{ schedule:'' });
				const custommodule = getSetting(extraData,'sched_tags');
				const visitSchedRules = getSetting(extraData,'add_visit_sched');
				decideAndLoad(config,axiosGetConfig);
				setCheckinConfig(config);
				setCustomModule(custommodule);
				setAddVisitSchedRules(visitSchedRules);
			}else{
				decideAndLoad('',axiosGetConfig);
				setCheckinConfig('')
			}
			setConfigLoading(false);
		}).catch(err=>{
			setConfigLoading(false);
		});
	}
	const makeSearchProp = () =>{
		var salesperson_id, date_from, date_to, region;
		var cust_code = JString(csValue?.value).isSafe();
		if(JString(spValue).isValid()){
			salesperson_id = spValue;
		}else{
			salesperson_id = 0;
		}
		if(!_.isEmpty(docDateRange)){
			date_from = format(docDateRange.startDate,defDateFormat);
			date_to = format(docDateRange.endDate,defDateFormat);
		}else{
			date_from = '';
			date_to = '';
		}

		if(cust_code){
			cust_code = csValue?.value || '';
		}

		if(JString(regionSelected).isValid()){
			region = regionSelected;
		}

		date_from = date_from === reset ? '' : date_from;
		date_to = date_to === reset ? '' : date_to;
		cust_code = cust_code === reset ? '' : cust_code;
		salesperson_id = salesperson_id === reset ? '' : salesperson_id;
		region = region === reset ? '' : region;

		return { date_from, date_to, salesperson_id, cust_code, region };
	}

	const loadAppointmentGroup = (axiosConfig = axiosApiConfig) =>{
		setAppointmentGroups([]);
		const params = axiosURIencode({ group:1 });
		API_Service.get(`${req_checkin_merchandising}?${params}`,axiosConfig).then(({data})=>{
			const { result, extraData } = data;
			if(parseInt(result) == 1){
				setAppointmentGroups(extraData);
			}
		});
	}
	const loadAppointmentData = (dateRange = {}, axiosConfig = axiosApiConfig) =>{
		setAppointmentData([]);
		setSearching(true);
		let { date_from, date_to, salesperson_id, cust_code, region } = makeSearchProp();
		if (!_.isEmpty(dateRange)) {
			date_from = format(dateRange.startDate,defDateFormat);
			date_to = format(dateRange.endDate,defDateFormat);
		}
		var obj = {
			active_status: 1,
			date_from,
			date_to,
			salesperson_id,
			cust_code,
			region
		};
		if(!date_from){
			obj.date_from = moment().startOf('month').format('YYYY-MM-DD');
			obj.date_to = moment().add(1,'month').endOf('month').format('YYYY-MM-DD');
		}
		if(obj.region === undefined || obj.region === null){
			obj.region = '';
		}
		var params = axiosURIencode(obj);
		API_Service.get(`${req_checkin_merchandising}?${params}`,axiosConfig).then(({data})=>{
			const { extraData } = data;
			const { pending, appointments } = extraData;
			setScheduleData(pending);
			setAppointmentData(appointments);
			setSearching(false);
			showFilter(false);
		}).catch(e=>setSearching(true));
	}

	const loadUnschedData = (axiosConfig = axiosApiConfig) => {
		API_Service.get(`${req_checkin_unsched}`,axiosConfig).then(({data})=>{
			const { result, extraData } = data;
			setUnschedAppointmentData(extraData);
		});
	}

	const generateCheckinReport = (type) =>{
		setPrintView(false);
		const { date_from, date_to, salesperson_id } = makeSearchProp();
		const date_range = `${date_from} to ${date_to}`;
		const client = gState.client;
		const url = checkin_report_url(client,salesperson_id,date_range,type);
		window.open(url,'_blank');
	}
	const loadSchedData = (axiosConfig = axiosApiConfig) =>{
		setScheduleData([]);
		setSearching(true);
		var { date_from, date_to, salesperson_id, cust_code } = makeSearchProp();
		if(!date_from){
			date_from = moment().format('YYYY-MM-DD');
			date_to = moment().add(2,'months').format('YYYY-MM-DD');
		}
		var params = {
			date_from,
			date_to,
			sched_status:0,
			active_status:1,
			status
		};
		if(salesperson_id){
			params = {...params,tech_assigned: salesperson_id}
		}
		if(cust_code){
			params = {...params, cust_code}
		}
		if(regionSelected){
			var region = getRegion(regionSelected);
			params = {...params, region};
		}
		params = axiosURIencode(params);
		API_Service.get(`${req_checkin_sched}?${params}`,axiosConfig).then(({data})=>{
			const { result, extraData } = data;
			if(result){
				if(extraData.length > 0){
					setScheduleData(extraData);
				}else{
					snack(language.no_data, "warning");
				}
			}

			setSearching(false);
		});
	}

	const snack = (msg, type = 'success') =>{
		enqueueSnackbar(msg,{
			variant:type,
			preventDuplicate:true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}
	const snackv2 = (msg, type = 'success') =>{
		enqueueSnackbar(msg,{
			variant: type,
			preventDuplicate: true,
			autoHideDuration: null,
			persist: true,
			action: (key) => (
				<IconButton onClick={() => closeSnackbar(key)}>
				  	<CloseIcon />
				</IconButton>
			),
		});
	}
	const clearValues = () =>{
		setCsValue(csValue=>(empty));
		setSpValue(spValue=>(empty));
		setRegionSelected(regionSelected=>(empty));
		setDocDateRange({});
		setStatus(status=>(empty));
	}

	const loadBranchPerCustomer = (axiosGetConfig) =>{
		API_Service.get(req_branch_per_cust, axiosGetConfig).then(({data})=>{
			const { result, extraData } = data;
			const rawData = rawCustomers || [];
			if(result == 1){
				setBranchPerCustomer(extraData);
				for (const cust_code in extraData) {
					if (Object.hasOwnProperty.call(extraData, cust_code)) {
						const branch_arr = extraData[cust_code];
						for (let ii = 0; ii < branch_arr.length; ii++) {
							const branch_obj = branch_arr[ii];
							const branchname = makebranchname(branch_obj.branch_name) || '-';
							const rawObj = {
								key: rawData.length,
								value: branchname,
								code: cust_code,
								branch_code: branch_obj.branch_code,
								branch_name: branchname,
								description:''
							};
							for (const column_name in branch_obj) {
								if (Object.hasOwnProperty.call(branch_obj, column_name)) {
									const column_value = branch_obj[column_name];
									if(
										JString(column_name).searchTokens(['bill','shipp','name'],true) &&
										JString(column_value).isValid()
									){
										rawObj.description += column_value;
									}
								}
							}
							rawData.push(rawObj);
						}
					}
				}
			}
			setRawCustomers(rawCustomers=>(rawData));
		});
	}
	const loadRegion = (axiosRequestConfig) =>{
		API_Service.get(`${req_region}`,axiosRequestConfig).then(({data})=>{
			const { result, extraData } = data;
			if(parseInt(result) === 1){
				var _data = [{name: '-', value: '0'}];
				for (let idx = 1; idx < extraData.length; idx++) {
					_data.push({ name: extraData[idx], value: idx.toString() });
				}
				_data.push({ name: language.region, value: reset});
				setRegionList(regionList => (_data));
			}
		});
	}

	const regionClick = () => {
		setRegionView(true);
	};

	const regionViewClose = (newValue) => {
		setRegionView(false);
		if (newValue) {
			setRegionSelected(newValue);
		}
	};

	const getRegion = props =>{
		for (let i = 0; i < regionList.length; i++) {
			const { name, value } = regionList[i];
			if(value == props){
				return name;
			}
		}
		return empty;
	}

	const loadCustomerList = (axiosRequestConfig, salesman = 0) =>{
		var req = `${req_cust}`;
		API_Service.get(req,axiosRequestConfig).then(({data})=>{
			const rawData = rawCustomers || [];
			if(data){
				var { result, extraData:apiCust } = data;
				var { customer } = apiCust;
				result = JString(result).toNumber();
				if(result === 1){
					var _data = [];
					var _outstanding = { };
					for (let idx = 0; idx < customer.length; idx++) {
						const obj = customer[idx];
						const { cust_company_name, cust_code } = obj;
						_data.push({ name: cust_company_name, secondary: cust_code, value: cust_code, address: makeaddress(obj), status: 1 });
						const rawObj = {
							key: rawData.length,
							value: cust_company_name,
							code: cust_code,
							branch_code: '',
							branch_name: '',
							description:''
						};
						_outstanding[cust_code] = obj.current_balance;
						for (const column_name in obj) {
							if (Object.hasOwnProperty.call(obj, column_name)) {
								const column_value = obj[column_name];
								if(
									JString(column_name).searchTokens(['bill','shipp','name'],true) &&
									JString(column_value).isValid()
								){
									rawObj.description += column_value;
								}
							}
						}
						rawData.push(rawObj);
					}
					_data.push({ name: language.customer, secondary: reset, value: reset });
					setCsList(csList=>(_data));
					setCsOutstanding(_outstanding);
				}
			}
			setRawCustomers(rawCustomers=>(rawData));
		});
	}

	const loadSalesmanList = (axiosRequestConfig, salesman = 0) =>{
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`,axiosRequestConfig).then(({data})=>{
			if(data){
				var { result, extraData:api_Sp } = data;
				result = JString(result).toNumber();
				if(result === 1){
					var _data = [];
					var _tchList = [];
					for (let idx = 0; idx < api_Sp.length; idx++) {
						const { staff_code, login_id, name, login_status } = api_Sp[idx];
						if (parseInt(login_status) === 1) {
							_data.push({ name: name, value: login_id });
							_tchList.push({ key: login_id, value: name });
						}
					}
					_data.push({ name: language.salesman, value: reset });
					setSpList(spList=>(_data));
					setTchList(tchList=>(_tchList));
				}
			}
		});
	}

	const loadMerchandiserList = (axiosRequestConfig) =>{
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_merchandiser}${parameters}`,axiosRequestConfig).then(({data})=>{
			if(data){
				var { result, extraData:api_Sp } = data;
				result = JString(result).toNumber();
				if(result === 1){
					var _data = [];
					for (let idx = 0; idx < api_Sp.length; idx++) {
						const { login_id, staff_code, name, login, company } = api_Sp[idx];
						_data.push({ key: login_id, value: staff_code, name: "("+login+") "+name, company: company ? company : null });
					}
					setMchList(spList=>(_data));
				}
			}
		});
	}

	const spViewClick = () => {
		setSpView(true);
	};

	const spViewClose = (newValue) => {
		setSpView(false);
		if (newValue) {
			setSpValue(newValue);
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			loadCustomerList(axiosGetConfig,newValue == reset ? 0 : newValue);
		}
	};

	const statusViewClick = () =>{
		setStatusView(true);
	}

	const onStatusViewClose = (newValue) =>{
		setStatusView(false);
		if (newValue) {
			setStatus(newValue);
		}
	}

	const getStatus = props =>{
		for (let i = 0; i < statusList.length; i++) {
			const { name, value } = statusList[i];
			if(value == props){
				return name;
			}
		}
		return empty;
	}

	const getSpName = props =>{
		for (let i = 0; i < spList.length; i++) {
			const { name, value } = spList[i];
			if(value == props){
				return name;
			}
		}
		return empty;
	}

	const onCustomerViewClose = (newValue) =>{
		showCustomerView(false);
		if (newValue) {
			setCsValue(newValue);
		}
	}

	const datefromto = (range) =>{
		const { startDate, endDate } = range;
		return moment(startDate).format('DD MMMM YY') + ' / ' + moment(endDate).format('DD MMMM YY');
	}

	const onSearchClick = () =>{
		decideAndLoad();
	}

	const getAppointmentData = () =>{
		if(_.isEmpty(selectedMch)){
			for (let i = 0; i < mchList.length; i++) {
				const { value } = mchList[i];
				if(appointmentData && appointmentData[value]){
					setSelectedMch(value);
					if(unschedAppointmentData && unschedAppointmentData[value]){
						const mergeResult = appointmentData[value].concat(unschedAppointmentData[value]);
						return mergeResult;
					}
					return appointmentData[value];
				}else if(unschedAppointmentData && unschedAppointmentData[value]){
					setSelectedMch(value);
					return unschedAppointmentData[value];
				}
			}
		}else{
			if(appointmentData && appointmentData[selectedMch]){
				if(unschedAppointmentData && unschedAppointmentData[selectedMch]){
					const mergeResult = appointmentData[selectedMch].concat(unschedAppointmentData[selectedMch]);
					return mergeResult;
				}
				return appointmentData[selectedMch];
			}else if(unschedAppointmentData && unschedAppointmentData[selectedMch]){
				return unschedAppointmentData[selectedMch];
			}
		}
	}

	const onSelectMch = (value) =>{
		setSelectedMch(value);
	}

	const printViewClose = (type) =>{
		generateCheckinReport(type);
	}

	const onSelectFilter = (value) => {
		setFilterStatus(value);
	}

	const customFilter = (status) => {
		const appointmentData = getAppointmentData();
		var dump = [];
		if(appointmentData){
			switch(filterStatus){
				case 'missed':
					dump = appointmentData.filter((sched) => {
						return sched.detail.appmnt_status == 3 || (sched.detail.appmnt_status == 0 && moment(sched.detail.appmnt_date).endOf('day').isBefore());
					});
					break;
				case 'pending':
					dump = appointmentData.filter((sched) => {
						return sched.detail.appmnt_status == 0 && !moment(sched.detail.appmnt_date).endOf('day').isBefore();
					});
					break;
				case 'attended':
					dump = appointmentData.filter((sched) => {
						return sched.detail.appmnt_status == 1;
					});
					break;
				case 'unsched':
					dump = appointmentData.filter((sched) => {
						return sched.detail.appmnt_status == 2;
					});
					break;
				case 'reset':
				default:
					dump = appointmentData;
					break;
			}
		}else{
			dump = appointmentData;
		}
		return dump;
	}

	const onFileUpload = (files) => {
		if (files.length > 0) {
			setFileData(files[0]);
		} else {
			setFileData(null);
		}
	}

	const uploadSchedule = () => {
		setLoading(true);
		const formdata = new FormData();
		formdata.append('file', fileData);
		API_Service.post(req_checkin_import, formdata, axiosApiConfig).then(({data}) => {
			const {result, message} = data;
			if (parseInt(result) === 1) {
				setImportOnView(false);
				snackv2(message, 'success');
				setLoading(false);
			} else {
				snackv2(message, 'error');
				setLoading(false);
			}
		});
	}

	const downloadTemplate = (newValue) => {
		if (_.isEmpty(tmpltCalanderDateRange)) { snack("Please select a date range to export data.", "error"); }
		if (tmpltSPValue === empty) { snack("Please choose a salesperson to export data.", "error"); }
		if (!_.isEmpty(tmpltCalanderDateRange) && tmpltSPValue !== empty) {
			const params = axiosURIencode({
				salesperson_id: tmpltSPValue,
				date_from: format(tmpltCalanderDateRange.startDate, defDateFormat),
				date_to: format(tmpltCalanderDateRange.endDate, defDateFormat)
			});
			API_Service.get(`${req_checkin_export}?${params}`, axiosApiConfig).then(({data}) => {
				const {result, extraData} = data;
				if (result) {
					window.open(extraData.url, '_blank');
					setExportOnView(false);
					const delay = setTimeout(() => {
						API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosApiConfig);
						clearTimeout(delay);
					}, 5000);
				}
			});
		}
	}

	const clearTmpltValues = () => {
		setTmpltSPValue(tmpltSPValue => (empty));
		setTmpltCalanderDateRange({});
	}

	const onCalenderDateChange = (e) => {
		const year = e.getFullYear();
		const month = e.getMonth();
		const firstDayOfMonth = new Date(year, month, 1);
		const lastDayOfMonth = new Date(year, month + 1, 0);

		setDocDateRange({startDate: firstDayOfMonth, endDate: lastDayOfMonth});
		loadAppointmentData({startDate: firstDayOfMonth, endDate: lastDayOfMonth});
	}

	return(
		<div>
			{
				JString(checkinConfig).isEqualDeep('merchandising') ?
					<div style={{margin:-20}}>
						{/* <Tabs
							indicatorColor={'primary'}
							textColor={'inherit'}
							variant={'fullWidth'}
							value={schedTabPosition}
							centered
							onChange={(e,v)=>setSchedTabPosition(v)}
							aria-label="simple tabs log">
							<Tab label={language.manage_sched}/>
							<Tab label={language.pending_sched}/>
						</Tabs> */}
						{/* {
							schedTabPosition == 0 && ( */}
								<Grid container spacing={1} style={{marginTop:5, width: '94vw'}}>
									<Grid item xs={2} component={Paper}>
										<div>
											{
												mchList?.length === 0 &&(
													<ListItem button>
														<ListItemIcon>
															<CircularProgress size={20}/>
														</ListItemIcon>
														<ListItemText primary="Loading..." />
													</ListItem>
												)
											}
											<FixedList
												listData={mchList}
												height={600}
												onClick={(item)=>onSelectMch(item.value)}
												selected={selectedMch}
											/>
										</div>
									</Grid>
									<Grid item xs={10} component={Paper}>
										<Grid container><Grid item xs={8}>
										<Box display="flex" justifyContent="flex-start">
											<Button
												style={{padding:5, marginRight:5}}
												variant="outlined"
												color="secondary"
												onClick={()=>onSelectFilter('missed')}>
												<FiberManualRecordIcon style={{margin:'0 0 0 0', padding:2, color:red[500]}}/>
												<FiberManualRecordIcon style={{margin:'0 3 0 0', padding:2, color:blue[500]}}/>{language.missedwreason}
											</Button>
											<Button
												style={{padding:5, marginRight:5}}
												variant="outlined"
												color="secondary"
												onClick={()=>onSelectFilter('pending')}>
												<FiberManualRecordIcon style={{margin:'0 3 0 0', padding:2, color:'#FF952B'}}/>{language.pending}
											</Button>
											<Button
												style={{padding:5, marginRight:5}}
												variant="outlined"
												color="secondary"
												onClick={()=>onSelectFilter('attended')}>
												<FiberManualRecordIcon style={{margin:'0 3 0 0', padding:2, color:green[500]}}/>{language.attended}
											</Button>
											<Button
												style={{padding:5, marginRight:5}}
												variant="outlined"
												color="secondary"
												onClick={()=>onSelectFilter('unsched')}>
												<FiberManualRecordIcon style={{margin:'0 3 0 0', padding:2, color:deepPurple[500]}}/>{language.uncheduled}
											</Button>
											<Button
												style={{padding:5, marginRight:5}}
												variant="outlined"
												color="secondary"
												onClick={()=>onSelectFilter('reset')}>
												<NotInterestedIcon style={{margin:'0 3 0 0', padding:2}}/>{language.reset_selection}
											</Button>
										</Box>
										</Grid>
										<Grid item xs={4}>
										<Box display="flex" justifyContent="flex-end">
											<Button
												style={{padding:5, marginRight:5}}
												variant="outlined"
												color="secondary"
												onClick={()=>setExportOnView(true)}>
												Export Template
											</Button>
											<Button
												style={{padding:5}}
												variant="outlined"
												color="secondary"
												onClick={()=>setImportOnView(true)}>
												Import Schedule
											</Button>
										</Box>
										</Grid>
										</Grid>
										<AppointmentView
											data={customFilter()}
											merchandiserList={mchList}
											language={language}
											customerList={csList}
											requestLoad={()=>decideAndLoad()}
											onSnack={snack}
											createSched={(e)=>{
												setCalenderDate(e);
												showNewSched(true);
												setCalenderDate(null);
											}}
											onNavigate={onCalenderDateChange}
										/>
									</Grid>
								</Grid>
							{/* )
						} */}
						{/* {
							schedTabPosition == 1 && (
								<div>
									{
										!JString(checkinConfig).isEqualDeep('merchandising') ?
											<ScheduleTable
												language={language}
												scheduleData={scheduleData}
											/>:
											<AppointmentTable
												language={language}
												appointmentData={appointmentGroups}
												onRowClick={(index)=>{}}
											/>
									}
								</div>
							)
						} */}
					</div>
					:
					<div style={{margin:-20}}>
						<ScheduleTable
							language={language}
							scheduleData={scheduleData}
							onRowClick={(obj)=>{
								setEditSched(obj);
							}}
						/>
					</div>
			}
			{
				!JString(checkinConfig).isEqualDeep('merchandising') && (
					<>
						<Fab
							className={commonStyles.fab}
							onClick={()=>showFilter(true)}
							variant={'circular'}
							size="small"
							color="primary"
							aria-label="add">
							{
								isSearching ?
									<CircularProgress color={'inherit'}/>:
									<FilterListIcon/>
							}
						</Fab>
						<Fab
							className={commonStyles.fab2nd}
							onClick={()=>{ showNewSched(!configLoading) }}
							variant={'circular'}
							size="small"
							color="primary"
							aria-label="add">
							{
								configLoading ?
									<CircularProgress color={'inherit'}/>:
									<AddIcon/>
							}
						</Fab>
					</>
				)
			}
			{
				schedTabPosition == 0 && JString(checkinConfig).isEqualDeep('merchandising') && (
					<Fab
						className={commonStyles.fab}
						onClick={()=>{ showNewSched(!configLoading) }}
						variant={'circular'}
						size="small"
						color="primary"
						aria-label="add">
						{
							configLoading ?
								<CircularProgress color={'inherit'}/>:
								<AddIcon/>
						}
					</Fab>
				)
			}
			{
				!_.isEmpty(checkinDataToview) && (
					<CheckinDetails
						checkinData={checkinDataToview}
						open={!_.isEmpty(checkinDataToview)}
						setClose={()=>setCheckinDataToView(undefined)}
						language={language}
						axiosApiConfig={axiosApiConfig}
					/>
				)
			}
			{
				isCustomerView && (
					<VirtualList
						open={isCustomerView}
						setClose={data=>onCustomerViewClose(data)}
						title={language.cust_list}
						data={csList}
						language={language}
					/>
				)
			}
			{
				exportOnView && (
					<AnyDialog maxWidth={'lg'} keepMounted open={exportOnView} onDismiss={() => setExportOnView(false)}>
						<div className={paperStyle.viewInRowDialog} style={{width: 1000}}>
							<Button
								className={commonStyles.dialogFilterBtnBig}
								color="inherit"
								onClick={() => setTmpltCalanderOnView(true)}
								size="small"
								endIcon={<KeyboardArrowRightRounded/>}
								startIcon={<EventNoteIcon/>}>
								<Typography style={{flex: 1, textAlign: 'left'}} component="p"
											className={commonStyles.searchBtnFont}>
									{!_.isEmpty(tmpltCalanderDateRange) ? datefromto(tmpltCalanderDateRange) : dateMenuOptions?.name}
								</Typography>
							</Button>
							<div style={{width: 20}}/>
							<Button
								className={commonStyles.dialogFilterBtn}
								color="inherit"
								onClick={() => setTmpltSPOnView(true)}
								size="small"
								endIcon={<KeyboardArrowDownRoundedIcon/>}
								startIcon={<PersonIcon/>}>
								<Typography component="p" className={commonStyles.searchBtnFont}>
									{getSpName(tmpltSPValue) || language.salesman}
								</Typography>
							</Button>
						</div>
						<Divider variant={'middle'}/>
						<div className={paperStyle.viewInRowDialogEnd}>
							<ClearButton
								color="inherit"
								onClick={() => clearTmpltValues()}
								size="small"
								startIcon={<ClearAll style={{color: red[400]}}/>}>
								<Typography component="p" className={commonStyles.searchBtnFont} style={{color: red[400]}}>
									{language.clear}
								</Typography>
							</ClearButton>

							<div style={{width: 10}}/>

							<Button
								disabled={isSearching}
								color="inherit"
								onClick={() => {
									downloadTemplate()
								}}
								size="small"
								startIcon={isSearching ? <CircularProgress size={20}/> : <FindInPage/>}>
								<Typography component="p" className={commonStyles.searchBtnFont}>
									{language.download}
								</Typography>
							</Button>
						</div>
					</AnyDialog>
				)
			}
			{
				tmpltCalenderOnView && (
					<DateRangePickerModal
						setPositive={range => {
							setTmpltCalanderDateRange(range);
							setTmpltCalanderOnView(false)
						}}
						setNegative={() => {
							setTmpltCalanderOnView(false)
						}}
						setClose={() => {
							setTmpltCalanderOnView(false)
						}}
						open={tmpltCalenderOnView}
						message={language.date_range}
						positive={language.ok}
						negative={language.cancel}
						minDate={1}
						maxDate={60}
						direction={'vertical'}
						hideDefinedRanges={true}
					/>
				)
			}
			{
				tmpltSPOnView && (
					<ListView
						classes={{
							paper: commonStyles.listView,
						}}
						id="ringtone-menu"
						keepMounted
						open={tmpltSPOnView}
						onClose={() => setTmpltSPOnView(false)}
						onSelect={(newValue) => {
							if (newValue) { setTmpltSPValue(newValue); }
							if (newValue === 'RESET') { setTmpltSPValue(empty); }
							setTmpltSPOnView(false);
						}}
						title={language.salesman || ''}
						language={language}
						options={spList}
						value={''}
					/>
				)
			}
			{
				spView && (
					<ListView
						classes={{
							paper: commonStyles.listView,
						}}
						id="ringtone-menu"
						open={spView}
						onClose={()=>setSpView(false)}
						onSelect={spViewClose}
						value={spValue}
						title={language.salesman || ''}
						language={language}
						options={spList}
					/>
				)
			}
			{
				statusView && (
					<ListView
						classes={{
							paper: commonStyles.listView,
						}}
						id="ringtone-menu"
						open={statusView}
						onClose={()=>setStatusView(false)}
						onSelect={onStatusViewClose}
						value={status}
						title={language.status || ''}
						language={language}
						options={statusList}
					/>
				)
			}
			{
				regionView && (
					<ListView
						classes={{
							paper: commonStyles.listView,
						}}
						id="ringtone-menu"
						open={regionView}
						onClose={()=>setRegionView(false)}
						onSelect={regionViewClose}
						value={regionSelected}
						title={language.region || ''}
						language={language}
						options={regionList}
					/>
				)
			}
			{
				openDateDoc && (
					<DateRangePickerModal
						setPositive={range => {setDocDateRange(range);setOpenDateDoc(false)}}
						setNegative={()=>{setOpenDateDoc(false)}}
						setClose={()=>{setOpenDateDoc(false)}}
						open={openDateDoc}
						message={language.date_range}
						positive={language.ok}
						negative={language.cancel}
					/>
				)
			}
			{
				editSched.open && (
					<AddVisitSched
						projectData={projectData}
						isEditing={editSched.open}
						editData={editSched.editData}
						requestReload={()=>loadSchedData()}
						outstandingBalance={csOutstanding}
						customModules={customModule}
						addVisitSchedRules={addVisitSchedRules}
						onSnack={snack}
						axiosGet={axiosApiConfig}
						customerList={csList}
						open={editSched.open}
						branchList={branchPercustomer}
						onDismiss={()=>setEditSched({open:false,editData:{}})}
						language={language}
						technicianList={tchList}/>
				)
			}
			{
				JString(checkinConfig).isEqualDeep('merchandising') ?
					<AddAppointmentMerchandiser
						onSnack={snack}
						axiosGet={axiosApiConfig}
						customerList={csList}
						open={addnewSched}
						onDismiss={()=>showNewSched(false)}
						language={language}
						branchList={branchPercustomer}
						merchandiserList={mchList}
						requestReload={()=>decideAndLoad()}
						date={calenderDate}
						selectedMch={selectedMch}	
						/>
					:
					addnewSched && (
						<AddVisitSched
							projectData={projectData}
							requestReload={()=>loadSchedData()}
							outstandingBalance={csOutstanding}
							customModules={customModule}
							addVisitSchedRules={addVisitSchedRules}
							onSnack={snack}
							axiosGet={axiosApiConfig}
							customerList={csList}
							open={addnewSched}
							branchList={branchPercustomer}
							onDismiss={(data)=>{showNewSched(false);setSchedCode(data);data ? setSMSView(true) : setSMSView(false)}}
							language={language}
							technicianList={tchList}/>
					)
			}
			{
				printView && (
					<GenerateView
						classes={{
							paper: commonStyles.listView,
						}}
						id="ringtone-menu"
						keepMounted
						open={printView}
						onClose={()=>setPrintView(false)}
						onSelect={printViewClose}
						value={'simply'}
						title={language.generate_type || ''}
						language={language}
						options={typeOptions}
					/>
				)
			}
			<AnyDialog maxWidth={'lg'} keepMounted open={filterOpen} onDismiss={()=>showFilter(false)}>
				<div className={paperStyle.viewInRowDialog} style={{width:1000}}>
					<Button
						className={commonStyles.dialogFilterBtnBig}
						color="inherit"
						onClick={()=>setOpenDateDoc(true)}
						size="small"
						endIcon={<KeyboardArrowRightRounded/>}
						startIcon={<EventNoteIcon/>}>
						<Typography style={{flex:1,textAlign:'left'}} component="p" className={commonStyles.searchBtnFont}>
							{ !_.isEmpty(docDateRange) ? datefromto(docDateRange) : dateMenuOptions?.name }
						</Typography>
					</Button>
					<div style={{width:20}}/>
					<Button
						disabled={csList.length === 0}
						className={commonStyles.dialogFilterBtn}
						color="inherit"
						onClick={()=>showCustomerView(true)}
						size="small"
						endIcon={ csList.length === 0 ? <CircularProgress size={20}/> : <KeyboardArrowDownRoundedIcon/>}
						startIcon={<BusinessIcon/>}>
						<Typography component="p" className={commonStyles.searchBtnFont}>
							{  csValue?.name ?  _.truncate(csValue?.name,{length:15}):  language.customer }
						</Typography>
					</Button>
					<div style={{width:20}}/>
					<Button
						className={commonStyles.dialogFilterBtn}
						color="inherit"
						onClick={()=>spViewClick()}
						size="small"
						endIcon={<KeyboardArrowDownRoundedIcon/>}
						startIcon={<PersonIcon/>}>
						<Typography component="p" className={commonStyles.searchBtnFont}>
							{  getSpName(spValue) || language.salesman }
						</Typography>
					</Button>
					<div style={{width:20}}/>
					{
						<Button
							className={commonStyles.dialogFilterBtn}
							color="inherit"
							onClick={()=>regionClick()}
							size="small"
							endIcon={<KeyboardArrowDownRoundedIcon/>}
							startIcon={<MapIcon/>}>
							<Typography component="p" className={commonStyles.searchBtnFont}>
								{ regionSelected !== empty ? getRegion(regionSelected) : language.region }
							</Typography>
						</Button>
					}
					<div style={{width:20}}/>
					{
						<Button
							className={commonStyles.dialogFilterBtn}
							color="inherit"
							onClick={()=>statusViewClick()}
							size="small"
							endIcon={<KeyboardArrowDownRoundedIcon/>}
							startIcon={<WorkOutlineIcon/>}>
							<Typography component="p" className={commonStyles.searchBtnFont}>
								{  getStatus(status) || language.status }
							</Typography>
						</Button>
					}

				</div>
				<Divider variant={'middle'} />
				<div className={paperStyle.viewInRowDialogEnd}>
					<ClearButton
						color="inherit"
						onClick={()=>clearValues()}
						size="small"
						startIcon={<ClearAll style={{color:red[400]}}/>}>
						<Typography component="p" className={commonStyles.searchBtnFont} style={{color:red[400]}}>
							{ language.clear }
						</Typography>
					</ClearButton>

					<div style={{width:10}}/>

					<Button
						disabled={isSearching}
						color="inherit"
						onClick={()=>{ onSearchClick() }}
						size="small"
						startIcon={ isSearching ? <CircularProgress size={20}/> : <FindInPage />}>
						<Typography component="p" className={commonStyles.searchBtnFont}>
							{ language.search_cap }
						</Typography>
					</Button>
				</div>
			</AnyDialog>
			<AnyDialog open={importOnView} onDismiss={()=>setImportOnView(false)}>
				<div className={paperStyle.dialogPaper}>
					<DropzoneArea
						onChange={(files) => {
							onFileUpload(files)
						}}
						dropzoneParagraphClass={classes.dropzoneText}
						dropzoneText={"Drag and drop a EXCEL file here or click"}
						acceptedFiles={['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
						filesLimit={1}
						clearOnUnmount={true}
					/>
				</div>
				<Divider variant={'middle'} />
				<div className={paperStyle.viewInRowDialogEnd}>
					<Button
						disabled={loading || !fileData}
						color="inherit"
						onClick={()=>uploadSchedule()}
						size="small"
						startIcon={ loading ? <CircularProgress size={20}/> : <FindInPage />}>
						<Typography component="p" className={commonStyles.searchBtnFont}>
							IMPORT
						</Typography>
					</Button>
				</div>
			</AnyDialog>
			<SendSMS
				open={smsView}
				setClose={()=>{setSMSView(false);setSchedCode([]);}}
				language={language}
				data={schedCode}
			/>
		</div>
	)
}
const AppointmentTable = React.memo(({ language, appointmentData, onRowClick })=>{
	if(appointmentData.length == 0){
		return null;
	}
	return(
		<MUIDataTable
			title={language.schedules}
			data={appointmentData}
			columns={appointmentHeadCell}
			options={{
				print:false,
				filter:true,
				selectableRows:'none',
				rowsPerPage : 125,
				rowsPerPageOptions : [125,140,165,200],
				responsive: 'simple',
				onRowClick:(rowData,{dataIndex})=>{
					onRowClick(dataIndex);
				},
				rowsSelected:[],
				setTableProps:()=>({size:'small'})
			}}
		/>
	)
},(prevProps,nextProps)=>prevProps.appointmentData.length === nextProps.appointmentData.length);

const ScheduleTable = React.memo(({ language, scheduleData, onRowClick })=>{
	if(scheduleData.length === 0){
		return null;
	}
	const onClick = (dataIndex) =>{
		onRowClick && onRowClick({
			open:true,
			editData: scheduleData[dataIndex]
		})
	}

	return(
		<MUIDataTable
			title={language.schedules}
			data={scheduleData}
			columns={schedHeadCell}
			options={{
				print:false,
				filter:true,
				selectableRows:'none',
				rowsPerPage : 125,
				rowsPerPageOptions : [125,140,165,200],
				responsive: 'simple',
				onRowClick:(rowData,{dataIndex})=>{
					onClick(dataIndex);
				},
				rowsSelected:[],
				setTableProps:()=>({size:'small'})
			}}
		/>
	)
},areEqualSched);
function areEqualSched(prevProps, nextProps){
	return prevProps.scheduleData.length === nextProps.scheduleData.length;
}
