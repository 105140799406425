import React, {createContext, useEffect, useState} from 'react';
import {ck_lang, ck_theme, ck_u_device_no, ck_u_role_id, cookieClear, empty} from '../Helper/Constants';
import { de } from '../Helper/Helper';
import { useLocation } from 'react-router-dom';
import Alert from '../Components/Alert';
import useCookieHook from './useCookieHook';
import History from './History';

const AppContext = createContext({
	translation: {},
	username: empty,
	password: empty,
	client: empty,
	tempState: {},
	emitter: {}
});

export default AppContext;

export const Layout = ({ children }) => {
	const location = useLocation();
	const [inactivityStatus, setInactivityStatus] = useState(false);
	const [cookies, setCookie, removeCookie] = useCookieHook([ck_theme, ck_lang, ck_u_role_id, ck_u_device_no]);

	const logoutFunction = () => {
		cookieClear((name) => {
			setCookie(name, empty);
			removeCookie(name, {path: '/'});
			removeCookie(name, {path: '/admin'});
			removeCookie(name, {path: '/admin/main'});
			removeCookie(name, {path: '/admin#/main'});
		});
		sessionStorage.removeItem('session');
		var timer = setTimeout(() => {
			History.push('/admin');
			clearTimeout(timer);
		}, 1000);
	}

	useEffect(() => {
		let timeout;
		
		const resetAutoLogoutTimer = () => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				setInactivityStatus(true);
			}, 15 * 60 * 1000); // 15 minutes
		}

		const handleUserActivity = () => {
			resetAutoLogoutTimer();
		};

		resetAutoLogoutTimer();
		window.addEventListener("click", handleUserActivity);

		return () => {
			clearTimeout(timeout);
			window.removeEventListener("click", handleUserActivity);
		};
	}, [location]);

	return <>
		<div>{children}</div>
		<Alert
			setPositive={() => logoutFunction()}
			setNegative={() => setInactivityStatus(false)}
			setClose={() => logoutFunction()}
			open={inactivityStatus}
			title={'LOGOUT'}
			message={'Click \'OK\' to proceed logout or \'CANCEL\' to remain on current page.'}
			positive={'OK'}
			negative={'CANCEL'}
			autoDispossal={true}
		/>
	</>;
};