import React, {useContext, useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import API_Service, {axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import {req_stock_info, req_stock_uom} from '../../Helper/Constants';
import AppContext from '../../Services/AppContext';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { de } from '../../Helper/Helper';

const useStyles = makeStyles({
	dlg: {
		minWidth: 300,
		margin: 5,
		marginLeft: 20,
		marginRight: 20
	},
	total: {
		float: 'left',
		color: 'grey',
		fontWeight: 'bold'
	}
});

export default function EditBarcode({open, onClose, language, product_code, uom, barcode, price, uomrate, price2, stdTitle, minTitle}) {
	const classes = useStyles();
	const [gState] = useContext(AppContext);
	const [newUOM, setNewUOM] = useState(uom)
	const [newBarcode, setNewBarcode] = useState(barcode);
	const [newPrice, setNewPrice] = useState(price);
	const [newPrice2, setNewPrice2] = useState(price2);
	const [rate, setRate] = useState(uomrate === '' ? 1 : uomrate);
	const [axiosPut, setAxiosPutConfig] = useState({});
	const {enqueueSnackbar, closeSnackbar} = useSnackbar();

	useEffect(() => {
		var axiosPut = axiosPutConfig(gState.client);
		setAxiosPutConfig(axiosPut);
		if (open) {
			setNewPrice(price);
			setNewPrice2(price2);
			
			if (uom !== '') {
				setNewUOM(uom);
			}
			if (barcode !== '') {
				setNewBarcode(barcode);
			}
			if (uomrate !== '') {
				setRate(uomrate);
			}
		}
	}, [open]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const saveCurrentChanges = () => {
		if (newUOM === '') {
			snack('Please fill in UOM', 'warning');
		} else {
			const params = axiosURIencode({
				product_code: product_code,
				uom: newUOM !== null && newUOM !== '' ? newUOM : uom,
				price: newPrice !== null && newPrice !== '' ? newPrice : price,
				price2: newPrice2 !== null && newPrice2 !== '' ? newPrice2 : price2,
				barcode: newBarcode !== null && newBarcode !== '' ? newBarcode : barcode,
				product_uom_rate: rate !== null && rate !== '' ? rate : uomrate,
				newUom: newUOM !== null ? newUOM : '',
				ori_uom: uom
			});
			if (uom === '') {
				API_Service.post(req_stock_info, params, axiosPut).then(({data}) => {
					console.log(data);
					const {result, message} = data;
					if (result) {
						snack(gState.translation.insert_success);
						closeDialog();
					} else {
						snack(gState.translation.insert_fail, 'warning');
					}
				});
			} else {
				API_Service.put(req_stock_uom, params, axiosPut).then(({data}) => {
					const {result, message} = data;
					if (result) {
						snack(gState.translation.update_success);
						setNewBarcode('');
						closeDialog();
					} else {
						snack(gState.translation.update_fail, 'warning');
					}
				});
			}
		}
	}

	const closeDialog = () => {
		setNewUOM('');
		setNewBarcode('');
		setRate('');
		setNewPrice(0);
		setNewPrice2(0);
		onClose();
	}

	return (
		<Dialog onClose={closeDialog} aria-labelledby="simple-dialog-title" open={open}>
			<div>
				<DialogTitle id="simple-dialog-title">
					{uom === '' ? language.new_uom : language.edit_uom}
				</DialogTitle>
				<TextField
					className={classes.dlg}
					id="input-uom"
					defaultValue={uom}
					// disabled={uom === '' ? false : true}
					placeholder={language.uom}
					onChange={(e) => setNewUOM(e.target.value)}
				/>
				<TextField
					className={classes.dlg}
					id="input-rate"
					defaultValue={price}
					placeholder={stdTitle ? stdTitle : language.std_price}
					InputProps={{
						inputComponent: CurrencyFormatCustom,
					}}
					onChange={(e) => setNewPrice(e.target.value)}
				/>
				<TextField
					className={classes.dlg}
					id="input-rate"
					defaultValue={price2}
					placeholder={minTitle ? minTitle : language.min_price}
					InputProps={{
						inputComponent: CurrencyFormatCustom,
					}}
					onChange={(e) => setNewPrice2(e.target.value)}
				/>

				<TextField
					className={classes.dlg}
					id="input-newBarcode"
					defaultValue={barcode}
					placeholder={language.barcode}
					InputProps={{
						inputComponent: NumberFormatCustom,
					}}
					onChange={(e) => setNewBarcode(e.target.value)}
				/>
				<TextField
					className={classes.dlg}
					id="input-rate"
					defaultValue={uomrate === '' ? 1 : uomrate}
					placeholder={language.uomrate}
					InputProps={{
						inputComponent: NumberFormatCustom,
					}}
					onChange={(e) => setRate(e.target.value)}
				/>
				<DialogActions>
					<Button
						onClick={() => {
							saveCurrentChanges()
						}}
						variant={'text'}
						color={'primary'}>
						{uom === '' ? language.add : language.update}
					</Button>
				</DialogActions>
			</div>
		</Dialog>
	)
}

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={false}
			allowLeadingZeros={true}
			isNumericString
			min={0}
			max={999999}
		/>
	);
}

function CurrencyFormatCustom(props) {
	const {inputRef, onChange, ...other} = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator={true}
			isNumericString
			prefix={'RM'}
			min={0}
			max={999999}
		/>
	);
}
