import {DateRangePicker} from "react-date-range";
import React, {useEffect, useState} from "react";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import './custom.css';
import { addDays, addMonths, subMonths } from "date-fns";

function HyperServerDateRange({onChange,months,direction,input,minDate,maxDate, limit, hideDefinedRanges}) {
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    useEffect(()=>{
        if(Object.keys(input).length !== 0){
            setState([input]);
        }
    },[input]);

    return (
        <DateRangePicker
            className={`Custom${hideDefinedRanges ? ' HideDefinedRanges' : ''}`}
            onChange={item => {
                setState([item.selection]);
                onChange(item.selection)
            }}
            {...(minDate && { minDate: addDays(new Date(), minDate) })}
            {...(maxDate && { maxDate: addDays(new Date(), maxDate) })}
            {...(limit && { minDate: subMonths(new Date(state[0].startDate), limit) })}
            {...(limit && { maxDate: addMonths(new Date(state[0].startDate), limit) })}
            months={months}
            ranges={state}
            rangeColors={['yellow']}
            direction={direction}
            scroll={{ enabled: false }}
            {...(hideDefinedRanges && { staticRanges: [], inputRanges: [] })}
        />
    )
}
export default HyperServerDateRange;
