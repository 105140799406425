import React, {useContext, useEffect, useState} from 'react';
import History from '../../Services/History';
import {useCommonStyles} from '../../Styles';
import AppContext from '../../Services/AppContext';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import {usePaperStyles} from '../../Styles/StylePaper';
import CryptData from "../../Helper/CryptData";
import PersistantData from '../../Services/PersistantData';
import Dialog from '@material-ui/core/Dialog';
import {
    ck_u_role_id,
    ck_u_username,
    empty,
    req_module_setting,
    req_salesman,
    req_stat_agentattend,
    req_stat_commissioncategory,
    req_stat_commissionmerchandiser,
    req_stat_oos,
    req_stat_photogallery,
    req_stat_timespent,
    req_stat_totalphoto,
    req_stat_totalst,
    reset
} from '../../Helper/Constants';
import JString from '@easytech-international-sdn-bhd/jstring';
import Typography from '@material-ui/core/Typography';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import _ from 'lodash';
import {de, getSetting} from '../../Helper/Helper';
import useCookieHook from "../../Services/useCookieHook";
import Button from '@material-ui/core/Button';
import {KeyboardArrowRightRounded} from '@material-ui/icons';
import EventNoteIcon from '@material-ui/icons/EventNote';
import moment from 'moment';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import {Bar, Line,} from 'react-chartjs-2';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import PersonIcon from '@material-ui/icons/Person';
import ListView from '../../Components/ListView';
import DescriptionIcon from '@material-ui/icons/Description';
import {BottomNavigation, BottomNavigationAction, DialogContent} from '@material-ui/core';
import {useSnackbar} from 'notistack';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const useStyles = makeStyles(theme => ({
	spacingTop: {
		marginTop: theme.spacing(2),
		flex: 1,
		padding: theme.spacing(1),
	},
	coordRoot: {
		'& .MuiTextField-root': {
			width: '100%',
			marginBottom: theme.spacing(1)
		},
		marginBottom: 50,
		padding: theme.spacing(1)
	},
	secRow: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: theme.spacing(1)
	},
	paperView: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%',
		height: '500px'
	},
	paperwidthView: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		width: '100%',
		height: '500px'
	},
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
	cardStyle: {
		padding: theme.spacing(1),
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	boldFontTitle: {
		fontWeight: 'bold',
		fontSize: 10
	},
	boldFontTitleRight: {
		fontWeight: 'bold',
		fontSize: 10,
		textAlign: 'right'
	},
	sales: {
		color: green[500],
		textAlign: 'right'
	},
	outstanding: {
		color: red[500],
		textAlign: 'right'
	},
	records: {
		textAlign: 'right'
	},
	pieLabel: {
		textAlign: 'center'
	}
}));

export default function Dashboard() {
	const classes = useStyles();
	const commonStyle = useCommonStyles();
	const paperStyle = usePaperStyles();

	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	const singleton = PersistantData.getInstance();
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}
	const [cookies, setCookie, removeCookie] = useCookieHook([ck_u_role_id, ck_u_username]);
	const defUsername = cookies(ck_u_username);
	var userAccessRole = cookies(ck_u_role_id);
	if (JString(defUsername).isValid()) {
		userAccessRole = JString(userAccessRole).isValid() ?
			new CryptData(userAccessRole, defUsername)
				.decryptAES()
				.getValue()
			: 5;
	}
	const {enqueueSnackbar} = useSnackbar();
	const [oosDetailsView, setOosDetailsView] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [agentAtted, setAgentAtted] = useState([]);
	const [agentList, setAgentList] = useState([]);
	const [oosDetails, setOosDetails] = useState([]);
	const [oosCust, setOosCust] = useState([]);
	const [oosTotal, setOosTotal] = useState([]);
	const [selection, setSelection] = useState([]);
	const [selectCust, setSelectCust] = useState(empty);
	const [openDateDoc, setDateDoc] = useState(false);
	const [DateRange, setDateRange] = useState(empty);
	const [date, setdate] = useState(empty);
	const [sp, setSp] = useState([]);
	const [commissionSP, setCommissionSP] = useState([]);
	const [type, setType] = useState([]);
	const [commissionCat, setCommissionCat] = useState([]);
	const [stDate, setSTDate] = useState([]);
	const [totalST, setTotalST] = useState([]);
	const [imageStaff, setImageStaff] = useState([]);
	const [totalImage, setTotalImage] = useState([]);
	const [timeCust, setTimeCust] = useState([]);
	const [totalTime, setTotalTime] = useState([]);
	const [spView, setSpView] = useState(false);
	const [spList, setSpList] = useState([]);
	const [spSelected, setSpSelected] = useState(empty);
	const [selectTime, setSelectTime] = useState(empty);
	const [timeAgentCust, setTimeAgentCust] = useState([]);
	const [timeDetailsView, setTimeDetailsView] = useState(false);
	const [timeSelection, setTimeSelection] = useState([]);
	const [photoType, setPhotoType] = useState([]);
	const [count, setCount] = useState([]);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			loadAgentAttend(axiosDefaultConfig(gState.client));
			loadOos(axiosDefaultConfig(gState.client));
			loadCommission(axiosDefaultConfig(gState.client));
			loadCommissionCategory(axiosDefaultConfig(gState.client));
			loadPhotoGallery(axiosDefaultConfig(gState.client));
			loadTotalST(axiosDefaultConfig(gState.client));
			loadTotalImage(axiosDefaultConfig(gState.client));
			loadTimeSpent(axiosDefaultConfig(gState.client));
			API_Service.get(req_module_setting, axiosDefaultConfig(gState.client)).then(({data}) => {
				const {result, extraData} = data;
				if (result == 1) {
					const _availableModules = getSetting(extraData, 'access_rights', {})[`${userAccessRole}`] || [];
					if (_availableModules.length > 0 && !_availableModules.includes('Dashboard')) {
						const _path = JString(_availableModules[0]).replaceInfinity(' ', '').str.toLowerCase();
						if (_path) {
							History.push(`/main/${_path}`);
						}
					}
				}
			});
			var parameters = '';
			if (gState.company) {
				const param = axiosURIencode({
					company: gState.company,
				});
				parameters = `?${param}`;
			}
			API_Service.get(`${req_salesman}${parameters}`, axiosDefaultConfig(gState.client)).then(({data}) => {
				if (data) {
					var {result, extraData: api_Sp} = data;
					result = JString(result).toNumber();
					if (result === 1) {
						var _data = [];
						for (let idx = 0; idx < api_Sp.length; idx++) {
							const {login, login_id, name} = api_Sp[idx];
							_data.push({name: "("+login+") "+name, value: login_id});
						}
						_data.push({name: language.salesman, value: reset});
						setSpList(spList => (_data));
					}
				}
			});
		}
	}, [gState.client, language]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const loadAgentAttend = (axiosRequestConfig, params) => {
		var link = req_stat_agentattend;
		if (params) {
			link += params;
		} else {
			link += '?';
			link += 'date_from='+moment().startOf('month').format('YYYY-MM-DD')+'&date_to='+moment().endOf('month').format('YYYY-MM-DD')+'&';
		}
		if (gState.company) {
			link += `company=${gState.company}`;
		}
		API_Service.get(link, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {extraData, result} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var {staff_code, total_checkin} = extraData;
					setAgentList(staff_code);
					setAgentAtted(total_checkin);
				} else {
					setAgentList([]);
					setAgentAtted([]);
					snack(language.out_no_record, 'warning');
				}
			}
		}).catch(de);
	}

	const loadCommission = (axiosRequestConfig, params) => {
		var link = req_stat_commissionmerchandiser;
		if (params) {
			link += params;
		} else {
			link += '?';
			link += 'date_from='+moment().startOf('month').format('YYYY-MM-DD')+'&date_to='+moment().endOf('month').format('YYYY-MM-DD')+'&';
		}
		if (gState.company) {
			link += `company=${gState.company}`;
		}
		API_Service.get(link, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {extraData, result} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var {salesperson, commission} = extraData;
					setSp(salesperson);
					setCommissionSP(commission);
				} else {
					setSp([]);
					setCommissionSP([]);
					snack(language.comm_no_record, 'warning');
				}
			}
		}).catch(de);
	}

	const loadCommissionCategory = (axiosRequestConfig, params) => {
		var link = req_stat_commissioncategory;
		if (params) {
			link += params;
		} else {
			link += '?';
			link += 'date_from='+moment().startOf('month').format('YYYY-MM-DD')+'&date_to='+moment().endOf('month').format('YYYY-MM-DD')+'&';
		}
		if (gState.company) {
			link += `company=${gState.company}`;
		}
		API_Service.get(link, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {extraData, result} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var {type, commission} = extraData;
					setType(type);
					setCommissionCat(commission);
				} else {
					setType([]);
					setCommissionCat([]);
					snack(language.comc_no_record, 'warning');
				}
			}
		}).catch(de);
	}

	const loadPhotoGallery = (axiosRequestConfig, params) => {
		var link = req_stat_photogallery;
		if (params) {
			link += params;
		} else {
			link += '?';
			link += 'date_from='+moment().startOf('month').format('YYYY-MM-DD')+'&date_to='+moment().endOf('month').format('YYYY-MM-DD')+'&';
		}
		if (gState.company) {
			link += `company=${gState.company}`;
		}
		API_Service.get(link, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {extraData, result} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var {type, count} = extraData;
					setPhotoType(type);
					setCount(count);
				} else {
					setPhotoType([]);
					setCount([]);
					snack(language.comc_no_record, 'warning');
				}
			}
		}).catch(de);
	}

	const loadOos = (axiosRequestConfig, params) => {
		var link = req_stat_oos;
		if (params) {
			link += params;
		} else {
			link += '?';
			link += 'date_from='+moment().startOf('month').format('YYYY-MM-DD')+'&date_to='+moment().endOf('month').format('YYYY-MM-DD')+'&';
		}
		if (gState.company) {
			link += `company=${gState.company}`;
		}
		API_Service.get(link, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {extraData, result} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var {cust_code, total_oos, oos_details} = extraData;
					setOosCust(cust_code);
					setOosTotal(total_oos);
					setOosDetails(oosDetails => (oos_details));
					setSelection(selection => ([]));
				} else {
					setOosCust([]);
					setOosTotal([]);
					setOosDetails([]);
					setSelection([]);
					snack(language.oos_no_record, 'warning');
				}
				setSelectCust(empty);
			}
		}).catch(de);
	}

	const loadTotalST = (axiosRequestConfig, params) => {
		var link = req_stat_totalst;
		if (params) {
			link += params;
		} else {
			link += '?';
			link += 'date_from='+moment().startOf('month').format('YYYY-MM-DD')+'&date_to='+moment().endOf('month').format('YYYY-MM-DD')+'&';
		}
		if (gState.company) {
			link += `company=${gState.company}`;
		}
		API_Service.get(link, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {extraData, result} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var {total_date_st, date} = extraData;
					setSTDate(date);
					setTotalST(total_date_st);
				} else {
					setSTDate([]);
					setTotalST([]);
					snack(language.st_no_record, 'warning');
				}
			}
		}).catch(de);
	}

	const loadTotalImage = (axiosRequestConfig, params) => {
		var link = req_stat_totalphoto;
		if (params) {
			link += params;
		} else {
			link += '?';
			link += 'date_from='+moment().startOf('month').format('YYYY-MM-DD')+'&date_to='+moment().endOf('month').format('YYYY-MM-DD')+'&';
		}
		if (gState.company) {
			link += `company=${gState.company}`;
		}
		API_Service.get(link, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {extraData, result} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var {staff_code, total_img} = extraData;
					setImageStaff(staff_code);
					setTotalImage(total_img);
				} else {
					setImageStaff([]);
					setTotalImage([]);
					snack(language.photo_no_record, 'warning');
				}
			}
		}).catch(de);
	}

	const loadTimeSpent = (axiosRequestConfig, params) => {
		var link = req_stat_timespent;
		if (params) {
			link += params;
		} else {
			link += '?';
			link += 'date_from='+moment().startOf('month').format('YYYY-MM-DD')+'&date_to='+moment().endOf('month').format('YYYY-MM-DD')+'&';
		}
		if (gState.company) {
			link += `company=${gState.company}`;
		}
		API_Service.get(link, axiosRequestConfig).then(({data}) => {
			if (data) {
				var {extraData, result} = data;
				result = JString(result).toNumber();
				if (result === 1) {
					var {cust_code, time_spent, agent_cust} = extraData;
					setTimeCust(cust_code);
					setTotalTime(time_spent);
					setTimeAgentCust(agent_cust);
				} else {
					setTimeCust([]);
					setTotalTime([]);
					setTimeAgentCust([]);
					snack(language.time_no_record, 'warning');
				}
			}
		}).catch(de);
	}

	const loadGraph = () => {
		const {startDate, endDate} = DateRange;
		var params = '?';
		if (DateRange) {
			params += `date_from=${moment(startDate).format('YYYY-MM-DD')}&date_to=${moment(endDate).format('YYYY-MM-DD')}&`;
		}
		if (spSelected) {
			params += `salesperson=${spSelected}&`;
		}
		loadAgentAttend(axiosDefaultConfig(gState.client), params);
		loadOos(axiosDefaultConfig(gState.client), params);
		loadCommission(axiosDefaultConfig(gState.client), params);
		loadCommissionCategory(axiosDefaultConfig(gState.client), params);
		loadPhotoGallery(axiosDefaultConfig(gState.client), params);
		loadTotalImage(axiosDefaultConfig(gState.client), params);
		loadTimeSpent(axiosDefaultConfig(gState.client), params);
		loadTotalST(axiosDefaultConfig(gState.client), params);
	}

	const datefromto = (range) => {
		if (range === empty) {
			setDateRange(empty);
			setdate(empty);
		} else {
			const {startDate, endDate} = range;
			setDateRange(range);
			setdate(moment(startDate).format('DD MMMM YY') + ' / ' + moment(endDate).format('DD MMMM YY'));
		}

	}

	const onClickOos = (idx) => {
		var target = oosCust[idx];
		var arr = [];
		for (let idx = 0; idx < oosDetails.length; idx++) {
			const row = oosDetails[idx];
			var searchString = row.cust_code;
			var isFound = JString(searchString).searchDeep(target);
			if (isFound) {
				arr.push(row);
				setSelectCust(oosDetails[idx]['cust_company_name']);
			}
		}
		setOosDetailsView(true);
		setSelection(arr);
	}

	const onClickTimeSpent = (idx) => {
		var target = timeCust[idx];
		var arr = [];
		for (let idx = 0; idx < timeAgentCust.length; idx++) {
			const row = timeAgentCust[idx];
			var searchString = row.cust_code;
			var isFound = JString(searchString).searchDeep(target);
			if (isFound) {
				arr.push(row);
				setSelectTime(timeAgentCust[idx]['cust_company_name']);
			}
		}
		setTimeDetailsView(true);
		setTimeSelection(arr);
	}

	const spViewClick = () => {
		setSpView(true);
	};

	const spViewClose = (newValue) => {
		setSpView(false);
		if (newValue) {
			setSpSelected(newValue);
		}
	};

	const getSpName = props => {
		for (let i = 0; i < spList.length; i++) {
			const {name, value} = spList[i];
			if (value == props) {
				return name;
			}
		}
		return empty;
	}

	const dataAgentAttend = {
		labels: agentList,
		datasets: [
			{
				label: language.agent_attend,
				data: agentAtted,
				borderColor: '#FEF200',
				backgroundColor: '#FEF200',
			}
		]
	}

	const dataOos = {
		labels: oosCust,
		datasets: [
			{
				label: language.out_of_stock,
				maxBarThickness: 50,
				data: oosTotal,
				borderColor: '#FEF200',
				backgroundColor: '#FEF200',
			}
		]
	}

	const dataComSP = {
		labels: sp,
		datasets: [
			{
				label: language.total_commission_merchand,
				maxBarThickness: 50,
				data: commissionSP,
				borderColor: '#FEF200',
				backgroundColor: '#FEF200',
			}
		]
	}

	const dataComCat = {
		labels: type,
		datasets: [
			{
				label: language.total_commission_cat,
				maxBarThickness: 50,
				data: commissionCat,
				borderColor: '#FEF200',
				backgroundColor: '#FEF200',
			}
		]
	}

	const dataPhotoCount = {
		labels: photoType,
		datasets: [
			{
				label: language.photo_count,
				maxBarThickness: 50,
				data: count,
				borderColor: '#FEF200',
				backgroundColor: '#FEF200',
			}
		]
	}

	const dataTotalST = {
		labels: stDate,
		datasets: [
			{
				label: language.total_st,
				maxBarThickness: 50,
				data: totalST,
				borderColor: '#FEF200',
				backgroundColor: '#FEF200',
			}
		]
	}

	const dataTotalImage = {
		labels: imageStaff,
		datasets: [
			{
				label: language.total_photo,
				maxBarThickness: 50,
				data: totalImage,
				borderColor: '#FEF200',
				backgroundColor: '#FEF200',
			}
		]
	}

	const dataTotalTimeSpent = {
		labels: timeCust,
		datasets: [
			{
				label: language.total_time_spent,
				data: totalTime,
				borderColor: '#FEF200',
				backgroundColor: '#FEF200',
			}
		]
	}

	return (
		<div>
			<div className={paperStyle.paperView}>
				<Paper className={paperStyle.viewInRowPad}>
					<div className={paperStyle.viewInRowSpace}>
						<div className={classes.secRow}>
							<Button
								className={commonStyle.searchBtnSize}
								color="inherit"
								onClick={() => setDateDoc(true)}
								size="small"
								endIcon={<KeyboardArrowRightRounded/>}
								startIcon={<EventNoteIcon/>}>
								<Typography component="p" className={commonStyle.searchBtnFont}>
									{!_.isEmpty(date) ? date : language.this_month}
								</Typography>
							</Button>
							{/* <Button
                                className={commonStyle.searchBtnSize}
                                color="inherit"
                                onClick={()=>setSpentDateDoc(true)}
                                size="small"
                                endIcon={<KeyboardArrowRightRounded/>}
                                startIcon={<EventNoteIcon/>}>
                                <Typography component="p" className={commonStyle.searchBtnFont}>
                                    { moment(spentDate).format('DD MMMM YY') }
                                </Typography>
                            </Button>
                            {
                                spentDateDoc === true && (
                                    <DatePicker
                                        autoOk
                                        orientation="landscape"
                                        variant="static"
                                        openTo="date"
                                        value={spentDate}
                                        onChange={setSpentDate}
                                    />
                                // <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                //     <DatePicker
                                //         margin={'dense'}
                                //         label="Date"
                                //         style={{ width: '100%' }}
                                //         inputVariant="outlined"
                                //         format="dd/MM/yyyy"
                                //         value={spentDate}
                                //         onChange={setSpentDate}/>
                                // </MuiPickersUtilsProvider>
                                )
                            } */}

							<div style={{width: 10}}/>
							<Button
								className={commonStyle.searchBtnSize}
								color="inherit"
								onClick={() => spViewClick()}
								size="small"
								endIcon={<KeyboardArrowDownRoundedIcon/>}
								startIcon={<PersonIcon/>}>
								<Typography component="p" className={commonStyle.searchBtnFont}>
									{getSpName(spSelected) || language.salesman}
								</Typography>
							</Button>
						</div>

						<div className={classes.secRow}>
							<Button
								color="inherit"
								onClick={() => {
									loadGraph()
								}}
								size="small"
								startIcon={<DescriptionIcon/>}>
								<Typography component="p" className={commonStyle.searchBtnFont}>
									{language.generate}
								</Typography>
							</Button>
						</div>
					</div>
				</Paper>
			</div>
			<div style={{height: 10}}/>
			{
				currentPage === 0 && (
					agentAtted.length > 0 && (
						<Paper className={classes.paperView}>
							<Line options={{
								maintainAspectRatio: false,
								plugins: {
									legend: {
										labels: {
											color: "white",
											font: {
												size: 18
											}
										},
										onClick: () => {}
									}
								},
								scales: {
									yAxes: {
										grid: {
											drawBorder: true,
											color: 'white',
										},
										ticks: {
											beginAtZero: true,
											color: 'white',
											fontSize: 12,
										}
									},
									xAxes: {
										grid: {
											drawBorder: true,
											color: 'white',
										},
										ticks: {
											beginAtZero: true,
											color: 'white',
											fontSize: 12,
										}
									},
								}
							}} data={dataAgentAttend}/>
						</Paper>
					)
				)
			}
			{
				currentPage === 1 && (
					oosTotal.length > 0 && (
						<Paper className={classes.paperView}>
							<Bar
								options={{
									maintainAspectRatio: false,
									plugins: {
										legend: {
											labels: {
												color: "white",
												font: {
													size: 18
												}
											},
											onClick: () => {}
										}
									},
									scales: {
										yAxes: {
											grid: {
												drawBorder: true,
												color: 'white',
											},
											ticks: {
												beginAtZero: true,
												color: 'white',
												fontSize: 12,
											}
										},
										xAxes: {
											grid: {
												drawBorder: true,
												color: 'white',
											},
											ticks: {
												beginAtZero: true,
												color: 'white',
												fontSize: 12,
											}
										},
									},
									onClick: function (evt, element) {
										if (element.length > 0) {
											onClickOos(element[0].index);
										}
									}
								}}
								data={dataOos}/>
						</Paper>
					)
				)
			}
			{
				currentPage === 2 && (
					<div className={classes.viewInRow}>
						{
							commissionSP.length > 0 && (
								<Paper className={classes.paperwidthView}>
									<Bar options={{
										maintainAspectRatio: false,
										plugins: {
											legend: {
												labels: {
													color: "white",
													font: {
														size: 18
													}
												},
												onClick: () => {}
											},
											tooltip: {
												callbacks: {
													label: function (context) {
														let label = context.dataset.label || '';
														label += ': RM ' + context.parsed.y;
														return label;
													}
												}
											}
										},
										scales: {
											yAxes: {
												grid: {
													drawBorder: true,
													color: 'white',
												},
												ticks: {
													beginAtZero: true,
													color: 'white',
													fontSize: 12,
												}
											},
											xAxes: {
												grid: {
													drawBorder: true,
													color: 'white',
												},
												ticks: {
													beginAtZero: true,
													color: 'white',
													fontSize: 12,
												}
											},
										}
									}} data={dataComSP}/>
								</Paper>
							)
						}

					</div>
				)
			}

			{
				currentPage === 3 && (
					<div>
						{
							commissionCat.length > 0 && (
								<Paper className={classes.paperwidthView}>
									<Bar options={{
										maintainAspectRatio: false,
										plugins: {
											legend: {
												labels: {
													color: "white",
													font: {
														size: 18
													}
												},
												onClick: () => {}
											},
											tooltip: {
												callbacks: {
													label: function (context) {
														let label = context.dataset.label || '';
														label += ': RM ' + context.parsed.y;
														return label;
													}
												}
											}
										},
										scales: {
											yAxes: {
												grid: {
													drawBorder: true,
													color: 'white',
												},
												ticks: {
													beginAtZero: true,
													color: 'white',
													fontSize: 12,
												}
											},
											xAxes: {
												grid: {
													drawBorder: true,
													color: 'white',
												},
												ticks: {
													beginAtZero: true,
													color: 'white',
													fontSize: 12,
												}
											},
										}
									}} data={dataComCat}/>
								</Paper>
							)
						}
					</div>
				)

			}
			<div className={classes.viewInRow}>
				{
					currentPage === 4 && (
						totalST.length > 0 && (
							<Paper className={classes.paperView}>
								<Bar options={{
									maintainAspectRatio: false,
									plugins: {
										legend: {
											labels: {
												color: "white",
												font: {
													size: 18
												}
											},
											onClick: () => {}
										}
									},
									scales: {
										yAxes: {
											grid: {
												drawBorder: true,
												color: 'white',
											},
											ticks: {
												beginAtZero: true,
												color: 'white',
												fontSize: 12,
											}
										},
										xAxes: {
											grid: {
												drawBorder: true,
												color: 'white',
											},
											ticks: {
												beginAtZero: true,
												color: 'white',
												fontSize: 12,
											}
										},
									}
								}} data={dataTotalST}/>
							</Paper>
						)
					)
				}
				{
					currentPage === 5 && (
						totalImage.length > 0 && (
							<Paper className={classes.paperView}>
								<Bar options={{
									maintainAspectRatio: false,
									plugins: {
										legend: {
											labels: {
												color: "white",
												font: {
													size: 18
												}
											},
											onClick: () => {}
										}
									},
									scales: {
										yAxes: {
											grid: {
												drawBorder: true,
												color: 'white',
											},
											ticks: {
												beginAtZero: true,
												color: 'white',
												fontSize: 12,
											}
										},
										xAxes: {
											grid: {
												drawBorder: true,
												color: 'white',
											},
											ticks: {
												beginAtZero: true,
												color: 'white',
												fontSize: 12,
											}
										},
									}
								}} data={dataTotalImage}/>
							</Paper>
						)
					)
				}
			</div>
			<div>
				{
					currentPage === 6 && (
						totalTime.length > 0 && (
							<Paper className={classes.paperView}>
								<Line options={{
									maintainAspectRatio: false,
									plugins: {
										legend: {
											labels: {
												color: "white",
												font: {
													size: 18
												}
											},
											onClick: () => {}
										},
										tooltip: {
											callbacks: {
												label: function (context) {
													let label = context.dataset.label || '';
													var hours = Math.floor(context.parsed.y / 60);
													var minutes = context.parsed.y % 60;
													label += ': ' + (hours !== 0 ? hours + ' hours ' : '') + minutes + ' minutes';
													return label;
												}
											}
										}
									},
									scales: {
										yAxes: {
											grid: {
												drawBorder: true,
												color: 'white',
											},
											ticks: {
												beginAtZero: true,
												color: 'white',
												fontSize: 12,
											}
										},
										xAxes: {
											grid: {
												drawBorder: true,
												color: 'white',
											},
											ticks: {
												beginAtZero: true,
												color: 'white',
												fontSize: 12,
											}
										}
									},
									onClick: function (evt, element) {
										if (element.length > 0) {
											onClickTimeSpent(element[0].index);
										}
									}
								}} data={dataTotalTimeSpent}/>
							</Paper>
						)
					)
				}
			</div>
			<div>
				{
					currentPage === 7 && (
						count.length > 0 && (
							<Paper className={classes.paperwidthView}>
								<Bar options={{
									maintainAspectRatio: false,
									plugins: {
										legend: {
											labels: {
												color: "white",
												font: {
													size: 18
												}
											}
										}
									},
									scales: {
										yAxes: {
											grid: {
												drawBorder: true,
												color: 'white',
											},
											ticks: {
												beginAtZero: true,
												color: 'white',
												fontSize: 12,
											}
										},
										xAxes: {
											grid: {
												drawBorder: true,
												color: 'white',
											},
											ticks: {
												beginAtZero: true,
												color: 'white',
												fontSize: 12,
											}
										},
									}
								}} data={dataPhotoCount}/>
							</Paper>
						)
					)
				}
			</div>
			<Dialog fullWidth={false}
					maxWidth={'lg'}
					open={oosDetailsView}
					aria-labelledby="form-dialog-title"
					onClose={() => {
						setOosDetailsView(false)
					}}>
				<DialogContent>
					<div className={classes.coordRoot}>
						{
							selectCust !== empty && (
								<Typography variant="h6">
									{selectCust}
								</Typography>
							)
						}
						<div style={{height: 10}}/>
						{
							selection.length > 0 && (
								selection.map((item, idx) =>
									<Typography component="p">
										{(idx + 1) + ". " + item.product_name}
									</Typography>
								)
							)
						}
					</div>
				</DialogContent>
			</Dialog>

			<Dialog fullWidth={false}
					maxWidth={'lg'}
					open={timeDetailsView}
					aria-labelledby="form-dialog-title"
					onClose={() => {
						setTimeDetailsView(false)
					}}>
				<DialogContent>
					<div className={classes.coordRoot}>
						{
							selectTime !== empty && (
								<Typography variant="h6">
									{selectTime}
								</Typography>
							)
						}
						<div style={{height: 10}}/>
						{
							timeSelection.length > 0 && (
								timeSelection.map((item, idx) =>
									<Typography component="p">
										{(idx + 1) + ". " + item['staff_code'] + " - " + (Math.floor(item['time_spent'] / 60) !== 0 ? Math.floor(item['time_spent'] / 60) + ' hours ' : '') + (item['time_spent'] % 60) + ' minutes'}
									</Typography>
								)
							)
						}
					</div>
				</DialogContent>
			</Dialog>
			<div style={{height: 100}}/>
			<BottomNavigation
				value={currentPage}
				onChange={(event, newValue) => {
					setCurrentPage(newValue);
				}}
				showLabels
				className={commonStyle.bottomNav}>
				<BottomNavigationAction label={language.agent_attend}/>
				<BottomNavigationAction label={language.out_of_stock}/>
				<BottomNavigationAction label={language.total_commission_merchand}/>
				<BottomNavigationAction label={language.total_commission_cat}/>
				<BottomNavigationAction label={language.total_st}/>
				<BottomNavigationAction label={language.total_photo}/>
				<BottomNavigationAction label={language.total_time_spent}/>
				<BottomNavigationAction label={language.photo_gallery}/>
			</BottomNavigation>

			<DateRangePickerModal
				setPositive={range => {
					datefromto(range);
					setDateDoc(false)
				}}
				setNegative={() => {
					setDateDoc(false)
				}}
				setClose={() => {
					setDateDoc(false)
				}}
				open={openDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
				reset={language.reset_selection}
			/>
			<ListView
				classes={{
					paper: commonStyle.listView,
				}}
				id="ringtone-menu"
				keepMounted
				open={spView}
				onClose={() => setSpView(false)}
				onSelect={spViewClose}
				value={spSelected}
				title={language.salesman || ''}
				language={language}
				options={spList}
			/>
		</div>
	)
}
