import React, {useContext, useEffect, useState} from 'react';
import {de, getSetting} from "../../Helper/Helper";
import JString from "@easytech-international-sdn-bhd/jstring";
import AppContext from "../../Services/AppContext";
import PersistantData from "../../Services/PersistantData";
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from "../../Services/API_Service";
import {empty, req_gallery_type, req_gallery_type_seq, req_gallery_type_status, req_module_setting} from "../../Helper/Constants";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Chip, Dialog, DialogTitle, Divider, FormControl, Grid, IconButton, lighten, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { red } from '@material-ui/core/colors';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { isEmpty } from 'lodash';
import jstring from "@easytech-international-sdn-bhd/jstring";
import { CloudUpload, Delete, Publish, Reorder, Undo, Update } from '@material-ui/icons';
import clsx from 'clsx';
import SearchBox from '../../Components/SearchBox';

const useStyles = makeStyles((theme) => ({
	fullWidth: {
		width: "100%",
	},
    viewInRowSpace: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
    root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	container:{
		maxHeight: '70vh',
	}
}));

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === 'light'
			? {
				color: theme.palette.secondary.main,
				backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			}
			: {
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.secondary.dark,
			},
	title: {
		flex: 1,
	},
}));

export default function SystemSettings() {
    const classes = useStyles();
    const toolbarClasses = useToolbarStyles();
    const [gState] = useContext(AppContext);
    const singleton = PersistantData.getInstance();
    var {translation: language} = gState;
    if (typeof (language) === 'undefined') {
        language = singleton.getlang();
    }
    const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [axiosConfig, setAxiosConfig] = useState({});
    const [axiosPut, setAxiosPutConfig] = useState({});
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [settings, setSettings] = useState({});
    const [galleryTypeList, setGalleryTypeList] = useState([]);
    const [galleryTypeView, setGalleryTypeView] = useState(false);
    const [galleryTypeName, setGalleryTypeName] = useState(empty);
    const [galleryTypeCommission, setGalleryTypeCommission] = useState(0);
    const [oriGalleryTypeID, setOriGalleryTypeID] = useState(empty);

    useEffect(() => {
        if (JString(gState.client).isValid()) {
            var axiosGetConfig = axiosDefaultConfig(gState.client);
            setAxiosConfig(axiosGetConfig);
            var axiosPut = axiosPutConfig(gState.client);
			setAxiosPutConfig(axiosPut);
            loadModule(axiosGetConfig);
        }
    }, [gState.client]);

    const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

    const loadModule = (axiosRequestConfig) => {
        API_Service.get(req_module_setting, axiosRequestConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
                const _customization = getSetting(extraData, 'customization', {});
                const _setting = getSetting(extraData, 'settings', {});
                const _modules = getSetting(extraData, 'modules', {});
                setSettings(_setting);
			}
		});
        API_Service.get(req_gallery_type, axiosRequestConfig).then(({data}) => {
            const {result, extraData} = data;
            if (result == 1) {
                setGalleryTypeList(extraData);
            }
        });
    }

    const galleryTypeOnClose = () => {
        setGalleryTypeView(false);
        setGalleryTypeName(empty);
        setGalleryTypeCommission(0);
        setOriGalleryTypeID(empty);
    }

    const addGalleryType = () => {
        let formData = new FormData();
		formData.append('type_name', galleryTypeName);
		formData.append('type_commission', galleryTypeCommission);

        if (galleryTypeName === empty) {
            snack('Name can not be blank.', 'error');
        } else {
            API_Service.post(req_gallery_type, formData, axiosConfig).then(({data}) => {
                const {result, message} = data;
                if (result) {
                    snack(message);
                    galleryTypeOnClose();
                    loadModule(axiosConfig);
                } else {
                    snack(message, 'warning');
                }
            });
        }
    }

    const changeGalleryTypeStatus = (input) => {
		var params = {
			type_id: input.type_id,
		};
		params = axiosURIencode(params);
		API_Service.put(req_gallery_type_status, params, axiosPut).then(({data}) => {
			var {result, message, extraData} = data;
			if (result == 1) {
				loadModule(axiosConfig);
				snack(message);
			} else {
				snack(message, 'error');
			}
		}).catch((err) => de(err.message));
	}

    const onClickGalleryTypeList = (rowData) => {
        setOriGalleryTypeID(galleryTypeList[rowData].type_id);
        setGalleryTypeName(galleryTypeList[rowData].type_name);
        setGalleryTypeCommission(galleryTypeList[rowData].type_commission);
        setGalleryTypeView(true);
    }

    const editGalleryType = () => {
        var params = {
			type_id: oriGalleryTypeID,
            type_name: galleryTypeName,
            type_commission: galleryTypeCommission
		};
        params = axiosURIencode(params);
        if (galleryTypeName === empty || oriGalleryTypeID === empty) {
            snack('Name can not be blank.', 'error');
        } else {
            API_Service.put(req_gallery_type, params, axiosPut).then(({data}) => {
                var {result, message, extraData} = data;
                if (result == 1) {
                    loadModule(axiosConfig);
                    galleryTypeOnClose();
                    snack(message);
                } else {
                    snack(message, 'error');
                }
            }).catch((err) => de(err.message));
        }
    }

    const updateGalleryTypeSequence = (e) => {
		let array = [];
		e.map((each,index)=>{
			array.push({
				type_id : each.type_id,
				sequence_no : index + 1
			})
		});
		const formdata = new FormData();
		formdata.append('array', JSON.stringify(array));
		API_Service.post(req_gallery_type_seq, formdata, axiosConfig).then(({data}) => {
			const {result} = data;
			if (parseInt(result) === 1) {
				snack(language.operation_success);
			} else {
				snack(language.operation_fail, 'error');
			}
		});
	}

    const galleryTypeHeadCells = [
        {
            name: "type_name",
            label: "Name",
            options: {
                display: true,
            }
        },
        {
            name: "type_commission",
            label: "Commision",
            options: {
                display: true,
            }
        },
        {
            name: "type_status",
            label: "Status",
            options: {
                display: true,
                customBodyRender: (value, {tableData, rowData}, updateValue) => {
                    const onChangeStatus = () => {
                        changeGalleryTypeStatus(galleryTypeList.find((content) => content.type_name === rowData[0]));
                    }
                    if (parseInt(value)) {
                        return (<IconButton
                            onClick={() => {
                                onChangeStatus();
                            }}>
                            <LockOpenIcon/>
                        </IconButton>);
                    } else {
                        return (<IconButton
                            onClick={() => {
                                onChangeStatus();
                            }}>
                            <LockIcon htmlColor={red[500]}/>
                        </IconButton>);
                    }
                }
            }
        },
    ];

    return(
        <div>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="h6" component="h1" fontWeight="bold">Outlet</Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography component="p" fontWeight="bold">Code Next Running No.: </Typography>
                            <Typography component="p">{settings.running_no?.outlet}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography component="p" fontWeight="bold">Code Reference No.: </Typography>
                            <Typography component="p">{settings.ref_no?.outlet}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography component="p" fontWeight="bold">Code Format: </Typography>
                            {/* <Typography component="p">{settings.ref_format?.outlet}</Typography> */}
                            {
                            settings.ref_format?.outlet
                                .replace(/{{(.*?)}}/g, (_, match) => match) // Remove placeholders
                                .split('-')
                                .map((data, index, array) => (
                                    <React.Fragment key={index}>
                                        <Chip label={data} />
                                        {index < array.length - 1 && "-"}
                                    </React.Fragment>
                                ))
                            }
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="h6" component="h1" fontWeight="bold">{language.photo_gallery}</Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails style={{display: 'block'}}>
                    <DraggableTable
                        classes={classes}
                        toolbarClasses={toolbarClasses}
                        title={language.photo_type}
                        columns={galleryTypeHeadCells}
                        data={galleryTypeList}
                        onChangeStatus={(output) => changeGalleryTypeStatus(output)}
                        onCreateNew={() => setGalleryTypeView(true)}
                        updateSequence={(e) => updateGalleryTypeSequence(e)}
                    />
                    {/* <MUIDataTable
                        title={language.photo_type}
                        className={classes.fullWidth}
                        data={galleryTypeList}
                        columns={galleryTypeHeadCells}
                        options={{
                            selectableRows: 'none',
                            print: false,
                            filter: false,
                            download: false,
                            viewColumns: false,
                            rowsPerPage: 150,
                            rowsPerPageOptions: [150, 300, 600, 900],
                            responsive: 'simple',
                            setTableProps: () => ({size: 'small'}),
                            // onCellClick: (colData, {colIndex, dataIndex}) => {
                            //     const lastColumnIndex = galleryTypeHeadCells.length - 1;
                            //     if (colIndex !== lastColumnIndex) {
                            //         onClickGalleryTypeList(dataIndex);
                            //     }
                            // },
                            customToolbar: () =>
                                <React.Fragment>
                                    <Tooltip title={'Add New Item'}>
                                        <IconButton
                                            edge={'end'}
                                            color={'primary'}
                                            onClick={() => setGalleryTypeView(true)} aria-label="addnew">
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </React.Fragment>
                        }}
                    /> */}
                </AccordionDetails>
            </Accordion>

            <Dialog fullScreen={fullScreen} onClose={() => galleryTypeOnClose()} aria-labelledby="simple-dialog-title" open={galleryTypeView}>
                <DialogTitle id="simple-dialog-title">
                    {oriGalleryTypeID === empty ? 'New' : 'Edit'} Gallery Type
                </DialogTitle>
                <div style={{width: 600, padding: theme.spacing(2)}}>
                    <div className={classes.viewInRowSpace}>
                        <TextField
                            id="input-type-name"
                            style={{width: '48%', height: 50}}
                            inputProps={{maxLength: 25, pattern: "[a-z]{1,15}"}}
                            label={'Name'}
                            value={galleryTypeName}
                            onChange={(e) => setGalleryTypeName(e.target.value)}
                        />
                        <TextField
                            id="input-type-name"
                            style={{width: '48%', height: 50}}
                            inputProps={{pattern: "[0-9]*"}}
                            label={'Commission'}
                            value={galleryTypeCommission}
                            onChange={(e) => setGalleryTypeCommission(e.target.value)}
                        />
                    </div>
                    <div style={{height: 20}}/>
                    <div style={{display: "flex"}}>
                        <Button
                            style={{marginLeft: 'auto'}}
                            variant="outlined"
                            disabled={galleryTypeName === empty || galleryTypeCommission === empty}
                            onClick={() => {
                                if (oriGalleryTypeID === empty) {
                                    addGalleryType();
                                } else {
                                    editGalleryType()
                                }
                            }}
                            color={'primary'}>
                            {oriGalleryTypeID === empty ? language.add : language.edit}
                        </Button>
                    </div>
                    <div style={{height: 20}}/>
                </div>
            </Dialog>
        </div>
    )
}

function DraggableTable({classes, toolbarClasses, title, columns, data, onChangeStatus, onCreateNew, updateSequence}) {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchRes, setSearchRes] = useState([]);
    const [localItems, setLocalItems] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [moveCount, setMoveCount] = useState(0);
    const [selected, setSelected] = useState([]);
    const [status, setStatus] = useState(1);
    const [open, setOpen] = useState(false);
    const [templateStatus, onChangeTemplateStatus] = useState(1);
    
    const dataToShowInTable = (!isEmpty(searchQuery) ? searchRes : localItems) || [];

    useEffect(()=>{
        // de("betty-data", data);
		setLocalItems(data);
	},[data])

    const handleDragEnd = (result, provided) => {
        de("betty-handleDragEnd", result);
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		setLocalItems((prev) =>{
			const temp = [...prev];
			const d = temp[result.destination.index];
			temp[result.destination.index] = temp[result.source.index];
			temp[result.source.index] = d;
			return temp;
		});

		setMoveCount(moveCount+1);
	};

    const onPressRow = (data) =>{
		setSearchRes([]);
		setSearchQuery("");
		for (let i = 0; i < localItems.length; i++){
			if (localItems[i].id == data.id){
				// setPreviewIndex(i);
				break;
			}
		}
	}

    const onUpdateStatus = () =>{
		setSearchQuery("");
		setSearchRes([]);
	}

    const onSearch = (_query) =>{
		setSearchRes([]);
		setSearchQuery(_query);
		const query = jstring(_query);
		if (query.isValid()){
			const _searchRes = [];
			const tokens = query.toString().split(" ");
			for (let i = 0; i < (localItems || []).length; i++){
				const {type_name} = localItems[i];
				let resCount = 0;
				for (let j = 0; j < tokens.length; j++){
					if (jstring(type_name).searchDeep(tokens[j])){
						resCount++;
					}
				}
				if (resCount >= tokens.length){
					_searchRes.push(localItems[i]);
				}
			}
			setSearchRes(_searchRes);
		}
	}

    const onChange = (e) => {
		setStatus(e.target.value);
		onActiveStatus(e.target.value)
	}
    
    const onActiveStatus = (e) =>{
		setSearchQuery("");
		setSearchRes([]);
		onChangeStatus(e);
		onChangeTemplateStatus(e);
	}

    const onUpdateSequence = () =>{
		updateSequence(dataToShowInTable);
        de("betty-onUpdateSequence", dataToShowInTable);
		setMoveCount(0);
	}

    const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

    const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Toolbar className={clsx(toolbarClasses.root)}>
                    <Typography className={toolbarClasses.title} variant="h6" id="tableTitle" component="div">
                        {title}
                    </Typography>
                    <FormControl>
                        <div style={{marginTop:8,marginRight:20}}>
                            <SearchBox onChangeText={onSearch} value={searchQuery} text={"Search template"} small={true}/>
                        </div>
                    </FormControl>
                    <Tooltip title="Update">
                        <IconButton disabled={moveCount === 0} aria-label="update" onClick={() => onUpdateSequence()}>
                            <Publish/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add New Item">
                        <IconButton edge={'end'} color={'primary'} aria-label="addnew" onClick={() => onCreateNew()}>
                            <AddIcon/>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <TableContainer className={classes.container}>
                    <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table" stickyHeader>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="droppable" direction="vertical">
                                {(droppableProvided) => (
                                    <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                        {
                                            dataToShowInTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                                                return (
                                                    <Draggable key={item.type_id} draggableId={item.type_id} index={index}>
                                                        {(draggableProvided, snapshot) => {
                                                            return (
                                                                <TableRow ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} style={{ ...draggableProvided.draggableProps.style, background: snapshot.isDragging ? "rgba(245,245,245, 0.75)" : "none" }}>
                                                                    <TableCell>
                                                                        {
                                                                            !jstring(searchQuery).isValid() ? 
                                                                                <div {...draggableProvided.dragHandleProps}>
                                                                                    <Reorder/>
                                                                                </div>
                                                                            :
                                                                                <div {...draggableProvided.dragHandleProps}/>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell onClick={()=>onPressRow(item)} component="th" id={item.type_id} scope="row" padding="none" >{item.type_name}</TableCell>
                                                                    <TableCell onClick={()=>onPressRow(item)}>{item.type_commission}</TableCell>
                                                                    <TableCell onClick={()=>onPressRow(item)}>
                                                                        {
                                                                            parseInt(item.type_status) ?
                                                                                <IconButton onClick={() => onChangeStatus(item)}>
                                                                                    <LockOpenIcon/>
                                                                                </IconButton>
                                                                            :
                                                                                <IconButton onClick={() => onChangeStatus(item)}>
                                                                                    <LockIcon htmlColor={red[500]}/>
                                                                                </IconButton>
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        }}
                                                    </Draggable>
                                                );
                                            })
                                        }
                                    </TableBody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Table>
                </TableContainer>
                <TablePagination
					rowsPerPageOptions={[100, 140, 165, 200]}
					component="div"
					count={dataToShowInTable.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={(event)=>handleChangeRowsPerPage(event)}
				/>
            </Paper>
        </div>
    )
}