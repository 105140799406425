import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import AppContext from '../../Services/AppContext';
import PersistantData from '../../Services/PersistantData';
import {grey} from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import {useCommonStyles} from '../../Styles';
import {
    ck_u_password,
    ck_u_username,
    defDateFormat,
    empty,
    oos_report_url,
    req_cust,
    req_cust_area,
    req_module_setting,
    req_report_oos,
    req_salesman,
    req_stock,
	req_report_oosreport,
	req_delete_file,
	req_cust_chain
} from '../../Helper/Constants';
import _ from 'lodash';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosURIencode} from '../../Services/API_Service';
import {useSnackbar} from 'notistack';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangePickerModal from '../../Components/DateRangePickerModal';
import moment from 'moment';
import VirtualListCB from '../../Components/VirtualListCB';
import format from 'date-fns/format';
import {de, getSetting} from '../../Helper/Helper';
import useCookieHook from "../../Services/useCookieHook";
import MUIDataTable from 'mui-datatables';
import {IconButton, Tooltip} from '@material-ui/core';
import {Assessment as AssessmentIcon} from '@material-ui/icons';
import GenerateView from '../../Components/GenerateView';

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	}, card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	textleft: {
		textAlign: "left"
	},
	dropzone: {
		width: '50%',
		borderRadius: 5
	},
	dropzoneText: {
		color: grey[600]
	},
	adverPaper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: theme.spacing(1.5)
	},
	adverView: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	textarea: {
		width: '100%'
	},
	dateBtn: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	statusBtn: {
		marginLeft: 30,
		width: 150
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	alignRight: {
		display: 'flex',
		marginBottom: 10,
		flex: 1,
		flexDirection: 'row'
	}
}));

const tableHead = [
	{
		name: "created_date",
		label: "Stock Take Date",
		options: {
			display: true
		}
	},
	{
		name: "cust_code",
		label: "Outlet Code",
		options: {
			display: true
		}
	},
	{
		name: "cust_company_name",
		label: "Outlet Name",
		options: {
			display: true
		}
	},
	{
		name: "cust_area",
		label: "Outlet Area",
		options: {
			display: true
		}
	},
	{
		name: "company",
		label: "Branch",
		options: {
			display: true,
			customBodyRender: (value) => value ? value.toUpperCase() : ''
		}
	},
	{
		name: "name",
		label: "Agent Name",
		options: {
			display: true
		}
	},
	{
		name: "product_code",
		label: "Product Code",
		options: {
			display: true
		}
	},
	{
		name: "product_name",
		label: "Product Name",
		options: {
			display: true
		}
	},
	{
		name: "oos",
		label: "No. of Times OOS",
		options: {
			display: true
		}
	}
]

export default function ROOS() {
	const commonStyle = useCommonStyles();
	const classes = useStyles();

	const singleton = PersistantData.getInstance();
	const [gState, setGState] = useContext(AppContext);
	var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);

	const {enqueueSnackbar, closeSnackbar} = useSnackbar();
	const [nClient, setClient] = useState(gState.client);

	const [splist, setSPlist] = useState([]);
	const [itemList, setItemList] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [oosList, setOosList] = useState([]);
	const [customerChainList, setCustomerChainList] = useState([]);

	const [oosDocDateRange, setOosoosDocDateRange] = useState({});
	const [openOosDateDoc, setOpenOosDateDoc] = useState(false);
	const [oosSpView, setOosSpView] = useState(false);
	const [oosOutletView, setOosOutletView] = useState(false);
	const [oosItemView, setOosItemView] = useState(false);
	const [spOosSelect, setOosSpSelected] = useState(empty);
	const [oosSalesperson, setOosSalesperson] = useState(empty);
	const [oosOutletSelect, setOosOutletSelected] = useState(empty);
	const [oosOutlet, setOosOutlet] = useState(empty);
	const [selectedCustomerChain, setSelectedCustomerChain] = useState(empty);
	const [selectedCustomerChainValue, setSelectedCustomerChainValue] = useState(empty);
	const [oosItemSelect, setOosItemSelected] = useState(empty);
	const [oosItem, setOosItem] = useState(empty);
	const [tableHeader, setTableHeader] = useState([]);

	const [areaList, setAreaList] = useState([]);
	const [areaView, setAreaView] = useState(false);
	const [selectedArea, setSelectedArea] = useState(empty);
	const [printView, setPrintView] = useState();
	const [customerChainView, setCustomerChainView] = useState(false);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			loadCustChainList(gState.client);
			loadStocksList(gState.client);
			loadConfig(gState.client);
			getAreaList(gState._client);
		}
		setTableHeader(tableHead);
	}, [gState.client, gState.translation]);

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const loadConfig = (_client = client) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _config = getSetting(extraData, 'customization', {});
			}
		});
	}

	const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {area_name, area_code, area_status} = extraData[idx];
					_data.push({name: area_name, secondary: area_code, value: area_code, status: parseInt(area_status)});
				}
				setAreaList(_data);
			}
		});
	}

	const AreaViewClick = () => {
		setAreaView(true);
	}

	const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

	const getOos = (_client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(oosDocDateRange)) {
			st_date = format(oosDocDateRange.startDate, defDateFormat);
			st_date_to = format(oosDocDateRange.endDate, defDateFormat);
		}
		var parameters = {
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: spOosSelect,
			cust_code: oosOutletSelect,
			cust_chain_id: selectedCustomerChainValue,
			product_code: oosItemSelect,
			area: selectedArea,
			username: cookies(ck_u_username)
		};
		if (gState.company) {
			parameters.company = gState.company;
		}
		const param = axiosURIencode(parameters);
		API_Service.get(`${req_report_oos}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					var _arr = extraData[idx];
					if (_arr['checkin_time']) {
						_arr['checkin_date'] = moment(_arr['checkin_time']).format('DD/MM/YYYY');
						_arr['checkin_time'] = moment(_arr['checkin_time']).format('HH:mmA');
					}
					if (_arr['checkout_time']) {
						_arr['checkout_date'] = moment(_arr['checkout_date']).format('DD/MM/YYYY');
						_arr['checkout_time'] = moment(_arr['checkout_time']).format('HH:mmA');
					}
					if (_arr['status'] === '1') {
						_arr['status'] = "In Progress";
					} else if (_arr['status'] === '2') {
						_arr['status'] = "Completed";
					}
					_arr['created_date'] = moment(_arr['created_date']).format('DD/MM/YYYY');
					_arr['checkout_coordinates'] = _arr['checkout_lat'] + ',' + _arr['checkout_lng'];
					_data.push(_arr);
				}
				setOosList(oosList => (_data));
			} else {
				setOosList(oosList => ([]));
				snack(language.no_data, 'warning');
			}
		});
	}

	const getSalesmanList = (_client = nClient) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
				}
				setSPlist(splist => (_data));
			} else {
				snack(gState.translation.not_found_salesperson, 'warning');
			}
		});
	}

	const getOutletList = (_client = nClient) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_cust}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_code,
						status: 0
					});
				}
				setOutletList(arr);
			}
		});
	}

	const loadCustChainList = (_client = client) => {
		API_Service.get(req_cust_chain, axiosDefaultConfig(_client)).then(({data}) => {
			if (data) {
				var {result, extraData } = data;
				if (parseInt(result) === 1) {
					var _arr = [];
					for (let i = 0; i < extraData.length; i++) {
						const {cust_chain_id, cust_chain_code, cust_chain_name, cust_chain_status} = extraData[i];
						if (parseInt(cust_chain_status) === 1) {
							_arr.push({
								name: cust_chain_name,
								secondary: cust_chain_code,
								value: cust_chain_id,
								status: 1
							});
						}else{
							_arr.push({
								name: cust_chain_name,
								secondary: cust_chain_code,
								value: cust_chain_id,
								status: 0
							});
						}
					}
					setCustomerChainList(() => (_arr));
				}
			}
		}).catch(err => de(err.message, 'customer chain list'));
	}

	const loadStocksList = (_client = nClient) => {
		var req = `${req_stock}-1`;
		API_Service.get(req, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			const {stock: stockApi} = extraData;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < stockApi.length; idx++) {
					const {product_name, product_code, product_status} = stockApi[idx];
					_data.push({name: product_name, secondary: product_code, value: product_code, status: parseInt(product_status)});
				}
				setItemList(itemList => (_data));
			}
			de(data);
		}).catch(de);
	}

	const OosSpViewClick = () => {
		setOosSpView(true);
	}

	const OosOutletViewClick = () => {
		setOosOutletView(true);
	}

	const OosSpViewClose = (newValue) => {
		setOosSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setOosSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setOosSalesperson(spname);
		}
	}

	const OosOutletViewClose = (newValue) => {
		setOosOutletView(false);
		if (newValue) {
			var outletvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					outletvalue += newValue[i].value;
				} else {
					outletvalue += newValue[i].value + ",";
				}
			}
			setOosOutletSelected(outletvalue);

			var outletname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					outletname += newValue[i].name;
				} else {
					outletname += newValue[i].name + ",";
				}
			}
			setOosOutlet(outletname);
		}
	}

	const OnCloseCustomerChainView = (data) => {
		setCustomerChainView(false);
		if (data) {
			var itemsvalue = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsvalue += data[i].value;
				} else {
					itemsvalue += data[i].value + ",";
				}
			}
			setSelectedCustomerChainValue(itemsvalue);

			var itemsname = '';
			for (let i = 0; i < data.length; i++) {
				if (i == data.length - 1) {
					itemsname += data[i].name;
				} else {
					itemsname += data[i].name + ",";
				}
			}
			setSelectedCustomerChain(itemsname);
		}
	}

	const OosItemViewClick = () => {
		setOosItemView(true);
	}

	const OosItemViewClose = (newValue) => {
		setOosItemView(false);
		if (newValue) {
			var itemvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					itemvalue += newValue[i].value;
				} else {
					itemvalue += newValue[i].value + ",";
				}
			}
			setOosItemSelected(itemvalue);

			var item = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					item += newValue[i].name;
				} else {
					item += newValue[i].name + ",";
				}
			}
			setOosItem(item);
		}
	}

	const getOosDate = () => {
		return !_.isEmpty(oosDocDateRange) ? moment(oosDocDateRange.startDate).format('DD MMMM YYYY') + " / " + moment(oosDocDateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

	const openPrintSelectView = () => {
		setPrintView(true);
	}

	const printViewClose = (type) => {
		generateOosReport(type);
		setPrintView(false);
	}

	const generateOosReport = (type, _client = client) => {
		if (type == 'simply') {
			var st_date = '';
			var st_date_to = '';
			if (!_.isEmpty(oosDocDateRange)) {
				st_date = format(oosDocDateRange.startDate, defDateFormat);
				st_date_to = format(oosDocDateRange.endDate, defDateFormat);
			}
			const date_range = `${st_date} to ${st_date_to}`;
			const branch = gState.company ? gState.company : '';
			var link = JString(oos_report_url)
				.replaceInfinity('@client', client)
				.replaceInfinity('@reportDate', encodeURIComponent(date_range))
				.replaceInfinity('@salespersonId', spOosSelect)
				.replaceInfinity('@cust_code', oosOutletSelect)
				.replaceInfinity('@cust_chain_id', selectedCustomerChainValue)
				.replaceInfinity('@area', selectedArea)
				.replaceInfinity('@branch', branch)
				.replaceInfinity('@username', cookies(ck_u_username))
				.replaceInfinity('@product_code', oosItemSelect)
				.str;
			window.open(link, "_blank");
		}

		if (type == 'normal') {
			var st_date = '';
			var st_date_to = '';
			if (!_.isEmpty(oosDocDateRange)) {
				st_date = format(oosDocDateRange.startDate, defDateFormat);
				st_date_to = format(oosDocDateRange.endDate, defDateFormat);
			}
			var parameters = {
				date_from: st_date,
				date_to: st_date_to,
				salespersonId: spOosSelect,
				cust_code: oosOutletSelect,
				cust_chain_id: selectedCustomerChainValue,
				product_code: oosItemSelect,
				area: selectedArea,
			};
			if (gState.company) {
				parameters.company = gState.company;
			}
			const param = axiosURIencode(parameters);
			API_Service.get(`${req_report_oosreport}?${param}`, axiosDefaultConfig(_client)).then(({data}) => {
				const {result, extraData} = data;
				if (result) {
					window.open(extraData.url, '_blank');
					const delay = setTimeout(() => {
						API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
						clearTimeout(delay);
					}, 5000);
				} else {
					snack(language.no_data, 'warning');
				}
			}).catch(err => {
				snack('The data is too large, please simplify the requirements', 'warning');
			});
		}
	}

	return (
		<div>
			{/* <Typography className={classes.heading}>{ language.generate_oos_report }</Typography> */}
			<div className={classes.viewInRow}>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => OosSpViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{oosSalesperson ? _.truncate(oosSalesperson, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => OosOutletViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{oosOutlet ? _.truncate(oosOutlet, {length: 20}) : language.customer}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setCustomerChainView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedCustomerChain ? _.truncate(selectedCustomerChain, {length: 20}) : "Outlet Chain"}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => AreaViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => OosItemViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{oosItem ? _.truncate(oosItem, {length: 20}) : language.items}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOpenOosDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getOosDate()}
					</Typography>
				</Button>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getOos()}>
					<Typography className={commonStyle.searchBtnFont}>
						{language.view}
					</Typography>
				</Button>
			</div>
			<div style={{height: 20}}/>
			{
				oosList.length > 0 && (
					<MUIDataTable
						title={language.oos}
						data={oosList}
						columns={tableHeader}
						options={{
							search: false,
							print: false,
							filter: false,
							download: false,
							selectableRows: 'none',
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 40, 65, 100],
							responsive: 'simple',
							onColumnViewChange: (changedColumn, action) => {
								if(tableHeader.some(item => changedColumn === item.name)){
									var index = tableHeader.findIndex(item => changedColumn === item.name);
								}
								switch(action){
									case 'add':
										tableHeader[index].options.display = true;
									case 'remove':
										tableHeader[index].options.display = false;
								}
								setTableHeader(tableHeader);
							},
							customToolbar: () =>
								<>
									<CheckinTableReportButton onGenerateReport={openPrintSelectView}/>
								</>
						}}
					/>
				)
			}

			<VirtualListCB
				open={oosSpView}
				setClose={data => OosSpViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={oosOutletView}
				setClose={data => OosOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={oosItemView}
				setClose={data => OosItemViewClose(data)}
				title={"Item"}
				data={itemList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={customerChainView}
				setClose={data => OnCloseCustomerChainView(data)}
				title={"Outlet Chain"}
				data={customerChainList}
				language={language}
				filteration={1}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setOosoosDocDateRange(range);
					setOpenOosDateDoc(false)
				}}
				setNegative={() => {
					setOpenOosDateDoc(false)
				}}
				setClose={() => {
					setOpenOosDateDoc(false)
				}}
				open={openOosDateDoc}
				message={language.date_range}
				positive={language.ok}
				negative={language.cancel}
			/>

			<GenerateView
				classes={{
					paper: commonStyle.listView,
				}}
				id="ringtone-menu"
				keepMounted
				open={printView}
				onClose={() => setPrintView(false)}
				onSelect={printViewClose}
				value={'simply'}
				title={language.generate_type || ''}
				language={language}
				options={[
					{name: 'Basic Version', value: 'simply'},
					{name: 'Detailed Version', value: 'normal'}
				]}
			/>
		</div>
	)
}

function CheckinTableReportButton({onGenerateReport}) {
	return (
		<React.Fragment>
			<Tooltip title={'Generate Report'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onGenerateReport()} aria-label="addnew">
					<AssessmentIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}
