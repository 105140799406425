import React, {useCallback, useContext, useEffect, useReducer, useState} from 'react';
import {useSnackbar} from 'notistack';
import AppContext from '../../Services/AppContext';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import ListIcon from '@material-ui/icons/List';
import JString from '@easytech-international-sdn-bhd/jstring';
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from '../../Services/API_Service';
import PersistantData from '../../Services/PersistantData';
import {
    ck_stocktable_list,
    ck_stocktable_type,
    empty,
    no_image,
    req_brand_status,
    req_category_status,
    req_module_setting,
    req_stock,
    req_stock_brand,
    req_stock_brandimage_insert,
    req_stock_brandimage_remove,
    req_stock_category,
    req_stock_catimage_insert,
    req_stock_catimage_remove,
    req_stock_def,
    req_stock_productseq,
    req_stock_status,
    req_stocksl_status
} from '../../Helper/Constants';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {useCookies} from 'react-cookie';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Image from 'material-ui-image';
import {usePaperStyles} from '../../Styles/StylePaper';
import {green, grey, red} from '@material-ui/core/colors';
import {NodataLottie} from '../../Components/LottieLoading/NodataLottie';
import SearchBox from '../../Components/SearchBox';
import _ from 'lodash';
import {SearchLottie} from '../../Components/LottieLoading/SearchLottie';
import ItemDetails from '../../Components/ItemDetails';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import MUIDataTable from 'mui-datatables';
import SyncIcon from '@material-ui/icons/Sync';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {BottomNavigation, BottomNavigationAction, CardActions, Tab, Tabs, TextField} from '@material-ui/core';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SingleUpload from '../../Components/SingleUpload';
import {arrayBufferToBase64, de, getSetting} from '../../Helper/Helper';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import GridList from "react-gridlist";
import {useCommonStyles} from '../../Styles';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddIcon from '@material-ui/icons/Add';
import AddProduct from '../../Components/AddProduct';
import AddCategory from '../../Components/AddCategory';
import AddBrand from '../../Components/AddBrand';
import CategoryDetails from '../../Components/CategoryDetails';
import BrandDetails from '../../Components/BrandDetails';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {Layers, LayersClear} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	media: {
		height: 150,
	},
	card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	sectionTitle: {
		color: grey[500],
		marginLeft: theme.spacing(1),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	left: {
		marginLeft: theme.spacing(1)
	},
	fontGrey: {
		color: 'grey'
	},
	marginRight: {
		marginRight: theme.spacing(1),
	},
	dropzoneText: {
		color: grey[600],
		fontSize: 13,
		width: 400
	}
}));

export default function StockList() {
	const classes = useStyles();
	const rowClass = usePaperStyles();
	const commonStyles = useCommonStyles();

	const [gState] = useContext(AppContext);
	const [axiosConfig, setAxiosConfig] = useState({});

	const {enqueueSnackbar} = useSnackbar();


	const [activeBrands, setActiveBrands] = useState([]);
	const [inactiveBrands, setInactiveBrands] = useState([]);
	const [brands, setBrands] = useState([]);
	const [chosenBrand, setChosenBrand] = useState(empty);
	const [chosenBrandId, setChosenBrandId] = useState(0);

	const [activeCategories, setActiveCategories] = useState([]);
	const [inactiveCategories, setInactiveCategories] = useState([]);
	const [activeStocks, setActiveStocks] = useState([]);
	const [inactiveStocks, setInactiveStocks] = useState([]);
	const [activeSearchResult, setActiveSearchResult] = useState([]);
	const [inActiveSearchResult, setInActiveSearchResult] = useState([]);
	const [tabPosition, setTabPosition] = useState(0);
	const [categories, setCategories] = useState([]);
	const [stocks, setStocks] = useState([]);
	const [searchResult, setSearchResult] = useState([]);
	const [searchQuery, setSearchQuery] = useState([]);
	const [chosenCategory, setChosenCategory] = useState(empty);
	const [chosenCategoryId, setChosenCategoryId] = useState(0);
	const [chosenStock, setChosenStock] = useState(-1);

	const [itemStatusAnchor, setItemStatusAnchor] = useState(null);
	const [categoryForImage, setCategoryForImage] = useState(null);
	const [categoryImage, setCategoryImage] = useState(null);
	const [openCategoryImageUpload, toggleCategoryImageUpload] = useState(false);

	const [brandForImage, setBrandForImage] = useState(null);
	const [brandImage, setBrandImage] = useState(null);
	const [openBrandImageUpload, toggleBrandImageUpload] = useState(false);

	const [cookies, setCookie] = useCookies([ck_stocktable_type]);
	const [integration, setIntegration] = useState(1);

	const [stockViewInList, toggleStockViewInList] = useState(cookies[ck_stocktable_type] == ck_stocktable_list);

	const [showStockDetails, toggleStockDetails] = useState(false);

	const [backdrop, setBackdrop] = useState(true);
	const [noData, setNoData] = useState(false);

	const [currentPage, setCurrentPage] = useState(0);

	const [newProductView, setNewProductView] = useState(false);

	const [mTabPosition, setMTabPosition] = useState(0);
	const [newCategoryView, setNewCategoryView] = useState(false);
	const [showCategoryDetails, toggleCategoryDetails] = useState(false);
	const [chosenMCategory, setChosenMCategory] = useState(-1);

	const [newBrandView, setNewBrandView] = useState(false);
	const [showBrandDetails, toggleBrandDetails] = useState(false);
	const [chosenMBrand, setChosenMBrand] = useState(-1);


	const singleton = PersistantData.getInstance();
	var {translation: language} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}

	useEffect(() => {
		if (JString(gState.client).isValid()) {
			var axiosGetConfig = axiosDefaultConfig(gState.client);
			setAxiosConfig(axiosGetConfig);
			loadStocks(0, 0, axiosGetConfig);
			loadConfig(axiosGetConfig)
		}
	}, [gState.client]);

	const loadConfig = (axiosRequestConfig) => {
		API_Service.get(req_module_setting, axiosRequestConfig).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _integration = getSetting(extraData, 'integration', {});
				setIntegration(_integration);
			}
		});
	}

	const loadStocks = (category_id = 0, brand_id = 0, config = {}, updated = null) => {
		// de("loadStocksInput", category_id, brand_id);
		de("loadStocksChoosen", chosenCategoryId, chosenBrandId);
		API_Service.get(`${req_stock}${category_id}&brand_id=${brand_id}`, _.isEmpty(config) ? axiosConfig : config).then(({data}) => {
			var {result, extraData} = data;
			result = JString(result).toNumber();
			if (result === 0) {
				snack(language.no_data, 'warning');
			} else {
				var {category, stock, brand} = extraData;
				var activeCatData = [];
				var inactiveCatData = [];
				if (category_id === 0) {
					for (let idx = 0; idx < category.length; idx++) {
						const {category_status} = category[idx];
						if (category_status == 1) {
							activeCatData.push(category[idx]);
						} else {
							inactiveCatData.push(category[idx]);
						}
					}
					setActiveCategories(() => (activeCatData));
					setInactiveCategories(() => (inactiveCatData));
					setCategories(() => (category));
					setChosenCategory(empty);
				}

				var activeBrandData = [];
				var inactiveBrandData = [];
				if (brand_id === 0) {
					for (let idx = 0; idx < brand.length; idx++) {
						const {brand_status} = brand[idx];
						if (brand_status == 1) {
							activeBrandData.push(brand[idx]);
						} else {
							inactiveBrandData.push(brand[idx]);
						}
					}
					setActiveBrands(() => (activeBrandData));
					setInactiveBrands(() => (inactiveBrandData));
					setBrands(() => (brand));
					setChosenBrand(empty);
				}

				var _data = [];
				var activeStockData = [];
				var inactiveStockData = [];
				for (let idx = 0; idx < stock.length; idx++) {
					const {product_name, product_code, product_status} = stock[idx];
					if (category_id === -1) {
						_data.push({name: product_name, value: product_code});
					}
					if (product_status == 1) {
						activeStockData.push(stock[idx]);
					} else {
						inactiveStockData.push(stock[idx]);
					}
				}
				setActiveStocks(() => (activeStockData));
				setInactiveStocks(() => (inactiveStockData));
				setStocks(() => (stock));
				setNoData(false);
				setBackdrop(false);
				if (updated) {
					const delay = setTimeout(() => {
						updated();
						clearTimeout(delay);
					}, 300);
				}
			}
		});
	}

	const changeProductStatus = (product_code, cb) => {
		var params = {
			product_code: product_code,
		};
		params = axiosURIencode(params);
		API_Service.put(req_stock_status, params, axiosConfig).then(({data}) => {
			var {result} = data;
			de(data);
			if (result == 1) {
				for (let ii = 0; ii < stocks.length; ii++) {
					const {product_code: toFindCode} = stocks[ii];
					if (JString(toFindCode).isEqualDeep(product_code)) {
						stocks[ii].product_status = stocks[ii].product_status == 1 ? 0 : 1;
					}
				}
			}
			cb(result == 1);
		}).catch((err) => de(err.message));


	}

	const changeSLStatus = (product_code, cb) => {
		var params = {
			product_code: product_code,
		};
		params = axiosURIencode(params);
		API_Service.put(req_stocksl_status, params, axiosConfig).then(({data}) => {
			var {result, message} = data;
			de(data);
			if (result == 1) {
				for (let ii = 0; ii < stocks.length; ii++) {
					const {product_code: toFindCode} = stocks[ii];
					if (JString(toFindCode).isEqualDeep(product_code)) {
						stocks[ii].is_stock_level = stocks[ii].is_stock_level == 1 ? 0 : 1;
					}
				}
			} else {
				snack(message, 'error');
			}
			cb(result == 1);
		}).catch((err) => de(err.message));
	}

	const changeCategoryStatus = (category_code, cb) => {
		var params = {
			category_code: category_code,
			client: gState.client,
			updated_by: gState.username
		};
		params = axiosURIencode(params);
		API_Service.put(req_category_status, params, axiosConfig).then(({data}) => {
			var {result} = data;
			if (result == 1) {
				for (let ii = 0; ii < categories.length; ii++) {
					const {categoryIdentifierId} = categories[ii];
					if (JString(categoryIdentifierId).isEqualDeep(category_code)) {
						categories[ii].category_status = categories[ii].category_status == 1 ? 0 : 1;
					}
				}
			}
			cb(result == 1);
		}).catch((err) => de(err.message));
	}

	const changeBrandStatus = (brand_code, cb) => {
		var params = {
			brand_code: brand_code,
			client: gState.client,
			updated_by: gState.username
		};
		params = axiosURIencode(params);
		API_Service.put(req_brand_status, params, axiosConfig).then(({data}) => {
			var {result} = data;
			if (result == 1) {
				for (let ii = 0; ii < brands.length; ii++) {
					const {brand_code} = brands[ii];
					if (JString(brand_code).isEqualDeep(brand_code)) {
						brands[ii].brand_status = brands[ii].brand_status == 1 ? 0 : 1;
					}
				}
			}
			cb(result == 1);
		}).catch((err) => de(err.message));
	}

	const onCategoryImageUpload = (files) => {
		if (files.length > 0) {
			const reader = new FileReader()

			reader.onabort = () => de('file reading was aborted')
			reader.onerror = () => de('file reading has failed')
			reader.onload = () => {
				const binaryStr = reader.result
				setCategoryImage(categoryImage => (binaryStr));
			}
			reader.readAsArrayBuffer(files[0])
		}
	}

	const onBrandImageUpload = (files) => {
		if (files.length > 0) {
			const reader = new FileReader()

			reader.onabort = () => de('file reading was aborted')
			reader.onerror = () => de('file reading has failed')
			reader.onload = () => {
				const binaryStr = reader.result
				setBrandImage(brandImage => (binaryStr));
			}
			reader.readAsArrayBuffer(files[0])
		}
	}

	const onSelectCategory = (name, id) => {
		// de("onSelectCategory");
		setBackdrop(true);
		//setCategories(()=>([]));
		setChosenCategory(name);
		setChosenCategoryId(id);
		setChosenBrandId(-1);
		loadStocks(id, -1);
		setSearchResult(() => ([]));
		setSearchQuery(() => ([]));
		setActiveSearchResult(() => ([]));
		setInActiveSearchResult(() => ([]));
		// setActiveCategories(()=>([]));
		// setInactiveCategories(()=>([]));
	}

	const onSelectBrand = (name, id) => {
		// de("onSelectBrand", id);
		setBackdrop(true);
		// setBrands(()=>([]));
		setChosenBrand(name);
		setChosenBrandId(id);
		setChosenCategoryId(-1);
		loadStocks(-1, id);
		setSearchResult(() => ([]));
		setSearchQuery(() => ([]));
		setActiveSearchResult(() => ([]));
		setInActiveSearchResult(() => ([]));
		// setActiveBrands(()=>([]));
		// setInactiveBrands(()=>([]));
	}

	const onDefaultCategory = () => {
		// de("onDefaultCategory");
		setBackdrop(true);
		loadStocks();
		setSearchResult(() => ([]));
		setSearchQuery(() => ([]));
		setActiveSearchResult(() => ([]));
		setInActiveSearchResult(() => ([]));
		setChosenCategoryId(0);
		setChosenBrandId(0);
	}

	const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

	const searchStock = (props, axiosConfig) => {
		var searchQuery = JString(props);
		if (searchQuery.isValid()) {
			var split = searchQuery
				.instanceOfString()
				.split(' ')
				.filter((item) => JString(item).isValid());
			setSearchQuery(() => (split));
			var formdata = new FormData();
			formdata.append('query', JSON.stringify(split));
			if (chosenCategoryId > 0) {
				formdata.append('category_id', chosenCategoryId);
			}
			if (chosenBrandId > 0) {
				formdata.append('brand_id', chosenBrandId);
			}
			API_Service.post(req_stock_def, formdata, axiosConfig).then(({data}) => {
				var {result, extraData} = data;
				de(data);
				result = JString(result).toNumber();
				if (result === 0) {
					setSearchResult(() => ([]));
					setActiveSearchResult(() => ([]));
					setInActiveSearchResult(() => ([]));
				} else {
					var activeStock = [];
					var inactiveStock = [];
					var {stock} = extraData;
					for (let idx = 0; idx < stock.length; idx++) {
						const {product_status} = stock[idx];
						if (product_status == 1) {
							activeStock.push(stock[idx]);
						} else {
							inactiveStock.push(stock[idx]);
						}
					}
					setActiveSearchResult(() => (activeStock));
					setInActiveSearchResult(() => (inactiveStock));
					setSearchResult(() => (stock));
				}
			}).catch((err) => de(err.message));
		} else {
			setSearchResult(() => ([]));
			setSearchQuery(() => ([]));
			setActiveSearchResult(() => ([]));
			setInActiveSearchResult(() => ([]));
		}
	}

	const searchThrottle = useCallback(_.debounce(searchStock, 500), [chosenCategoryId, chosenBrandId]);

	const onClickStock = (index) => {
		setChosenStock(() => (index));
		toggleStockDetails(() => (true));
	}

	const onClickStockList = (item_code, arr) => {
		for (let index = 0; index < arr.length; index++) {
			const {product_code} = arr[index];
			if (item_code == product_code) {
				onClickStock(index);
			}
		}
	}

	const onUpdateStock = (newItem) => {
		if (newItem) {
			var data = searchResult.length === 0 ? stocks : searchResult;
			data[chosenStock] = newItem;
			setStocks(() => (data));
			snack(language.record_updated);
		}
	}

	const onClickCategoryList = (item_code, arr) => {
		for (let index = 0; index < arr.length; index++) {
			const {categoryIdentifierId} = arr[index];
			if (item_code == categoryIdentifierId) {
				onClickCategory(index);
			}
		}
	}

	const onClickBrandList = (item_code, arr) => {
		for (let index = 0; index < arr.length; index++) {
			const {brand_code} = arr[index];
			if (item_code == brand_code) {
				onClickBrand(index);
			}
		}
	}

	const onClickCategory = (index) => {
		setChosenMCategory(() => (index));
		toggleCategoryDetails(() => (true));
	}

	const onClickBrand = (index) => {
		setChosenMBrand(() => (index));
		toggleBrandDetails(() => (true));
	}

	const onUpdateCategory = (newItem) => {

		if (newItem) {
			loadCategory();
		}
		// if(chosenMCategory === -1){
		//     loadCategory();
		// }else{
		//     if(newItem){
		//         var data = categories;
		//         data[chosenMCategory] = newItem;
		//         setCategories(()=>(data));
		//         snack(language.record_updated);
		//     }
		// }

	}

	const onUpdateBrand = (newItem) => {
		if (newItem) {
			loadBrand();
		}
	}

	const loadCategory = () => {
		API_Service.get(`${req_stock_category}`, axiosConfig).then(({data}) => {
			if (!_.isEmpty(data)) {
				var activeCatData = [];
				var inactiveCatData = [];
				for (let idx = 0; idx < data.length; idx++) {
					const {category_status} = data[idx];
					if (category_status == 1) {
						activeCatData.push(data[idx]);
					} else {
						inactiveCatData.push(data[idx]);
					}
				}
				setActiveCategories(() => (activeCatData));
				setInactiveCategories(() => (inactiveCatData));
				setCategories(() => (data));
				//snack(language.record_updated);
			} else {

			}
		});
	}

	const loadBrand = () => {
		API_Service.get(`${req_stock_brand}`, axiosConfig).then(({data}) => {
			var activeBrandData = [];
			var inactiveBrandData = [];
			if (!_.isEmpty(data)) {
				for (let idx = 0; idx < data.length; idx++) {
					const {brand_status} = data[idx];
					if (brand_status == 1) {
						activeBrandData.push(data[idx]);
					} else {
						inactiveBrandData.push(data[idx]);
					}
				}
				setActiveBrands(() => (activeBrandData));
				setInactiveBrands(() => (inactiveBrandData));
				setBrands(() => (data));
				//snack(language.record_updated);
			} else {
				setActiveBrands(() => (activeBrandData));
				setInactiveBrands(() => (inactiveBrandData));
				setBrands(() => ([]));
			}
		});
	}

	const changeTableView = () => {
		de("changeTableView", chosenCategoryId, chosenBrandId);
		if (stockViewInList) {
			setCookie(ck_stocktable_type, '', {path: '/'});
		} else {
			setCookie(ck_stocktable_type, ck_stocktable_list, {path: '/'});
		}
		loadStocks(chosenCategoryId, chosenBrandId, axiosConfig, () => {
			if (stockViewInList) {
				toggleStockViewInList(false);
			} else {
				toggleStockViewInList(true);
			}
			// toggleStockViewInList(stockViewInList=>(!stockViewInList));
		});
	}

	const toggleCategoryImage_inDB = (__category, is_delete = false) => {
		const __image = arrayBufferToBase64(categoryImage);
		const axiosConfig = axiosPutConfig(gState.client);
		const formdata = new FormData();
		formdata.append('id', __category.categoryIdentifierId);
		formdata.append('category_image', __image);

		const api = is_delete ?
			API_Service.put(req_stock_catimage_remove,
				axiosURIencode({
					id: __category.categoryIdentifierId
				}),
				axiosConfig
			) :
			API_Service.post(req_stock_catimage_insert, formdata, axiosConfig);

		api.then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				loadCategory(0);
				// if(is_delete){
				//     loadStocks(0);
				// }else{
				//     if(extraData && extraData.length > 0){
				//         const category = extraData[0];
				//         const { categoryIdentifierId } = category;
				//         for (let i = 0; i < categories.length; i++) {
				//             const { categoryIdentifierId: toFindId } = categories[i];
				//             if(categoryIdentifierId == toFindId){
				//                 categories[i] = category;
				//                 break;
				//             }
				//         }
				//         setCategories(categories);
				//     }
				// }
				snack(is_delete ? language.deleted : language.upload_success);
			} else {
				snack(is_delete ? language.delete_fail : language.upload_fail, 'error');
			}
			setCategoryForImage(null);
		}).catch(console.debug);
		toggleCategoryImageUpload(false);
	}

	const toggleBrandImage_inDB = (__brand, is_delete = false) => {
		const __image = arrayBufferToBase64(brandImage);
		const axiosConfig = axiosPutConfig(gState.client);
		const formdata = new FormData();
		formdata.append('id', __brand.brand_code);
		formdata.append('brand_image', __image);

		const api = is_delete ?
			API_Service.put(req_stock_brandimage_remove,
				axiosURIencode({
					id: __brand.brand_code
				}),
				axiosConfig
			) :
			API_Service.post(req_stock_brandimage_insert, formdata, axiosConfig);

		api.then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				loadBrand();
				// if(is_delete){
				//     loadStocks(0);
				// }else{
				//     if(extraData && extraData.length > 0){
				//         const brand = extraData[0];
				//         const { brand_code } = brand;
				//         for (let i = 0; i < brands.length; i++) {
				//             const { brand_code: toFindId } = brands[i];
				//             if(brand_code == toFindId){
				//                 brands[i] = brand;
				//                 break;
				//             }
				//         }
				//         setBrands(brands);
				//     }
				//}
				snack(is_delete ? language.deleted : language.upload_success);
			} else {
				snack(is_delete ? language.delete_fail : language.upload_fail, 'error');
			}
			setBrandForImage(null);
		}).catch(console.debug);
		toggleBrandImageUpload(false);
	}

	const itemHeadCell = [
		{
			name: "product_code",
			label: "Code",
			options: {
				display: true
			}
		},
		{
			name: "product_name",
			label: "Name",
			options: {
				display: true
			}
		},
		{
			name: "product_desc",
			label: "Description",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return <p>{JString(value).trimHtml().str || '-'}</p>;
				}
			}
		},
		// {
		//     name : "has_cust_product",
		//     label: "VIP QTY",
		//     options : {
		//         display : true,
		//         customBodyRender : (value, tableMeta, updateValue) => {
		//             switch(value){
		//                 case "1" : return (<CheckCircleIcon />); break;
		//                 case "0" : return (<CancelIcon color={"error"}/>); break;
		//                 default : return (null); break;
		//             }
		//         }
		//     }
		// },
		// {
		//     name : "product_current_quantity",
		//     label: "Available QTY",
		//     options : {
		//         display : true,
		//         sort: false,
		//         setCellProps: () => ({
		//             align: "center"
		//         })
		//     }
		// },
		// {
		//     name : "product_virtual_quantity",
		//     label: "Virtual QTY",
		//     options : {
		//         display : true,
		//         sort: false,
		//         setCellProps: () => ({
		//             align: "center"
		//         })
		//     }
		// },
		// {
		//     name : "commission_point",
		//     label: "Commission Point",
		//     options : {
		//         display : true,
		//         sort: false,
		//         setCellProps: () => ({
		//             align: "center"
		//         })
		//     }
		// },
		{
			name: "sequence_no",
			label: "Sequence No",
			options: {
				display: true,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowIndex: dataIndex, rowData} = tableMeta;
					const updateSequence = (v) => {
						var params = {
							product_code: rowData[0],
							sequence: v
						};
						params = axiosURIencode(params);
						API_Service.put(req_stock_productseq, params, axiosConfig).then(({data}) => {
							var {result} = data;
							if (result == 1) {
								snack(language.sequence_update_success);
							} else {
								snack(language.sequence_update_failed, 'warning');
							}
						}).catch((err) => de(err.message));
					}
					return (
						<TextField inputProps={{style: {width: 50, textAlign: 'center'}}} value={value || ''} type='int'
								   onChange={event => {
									   updateValue(parseInt(event.target.value));
									   updateSequence(parseInt(event.target.value));
								   }}/>
					);
				},
				setCellProps: () => ({
					align: "center"
				})
			}
		}, {
			name: "product_status",
			label: "Status",
			options: {
				display: true,
				sort: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowIndex: dataIndex} = tableMeta;
					const onChangeStatus = () => {
						searchResult.length === 0 ?
							changeProductStatus(tabPosition == 0 ? activeStocks[dataIndex]['product_code'] : inactiveStocks[dataIndex]['product_code'], (isUpdated) => {
								if (isUpdated) {
									if (value == 0 || value == 2) {
										updateValue("1");
									} else {
										updateValue("0");
									}
								}
							})
							:
							changeProductStatus(searchResult[dataIndex]['product_code'], (isUpdated) => {
								if (isUpdated) {
									if (value == 0 || value == 2) {
										updateValue("1");
									} else {
										updateValue("0");
									}
								}
							})
					}
					switch (value) {
						case "1" :
							return (
								<Button onClick={() => onChangeStatus()}>
									<Chip size="small" icon={<DoneIcon/>} label="Active"/>
								</Button>
							);
							break;
						case "0" :
							return (
								<Button onClick={() => onChangeStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						case "2" :
							return (
								<Button onClick={() => onChangeStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						default :
							return (null);
							break;
					}
				}
			}
		}
	];

	const categoryHeadCell = [
		{
			name: "category_id",
			label: "--",
			options: {
				display: false
			}
		},
		{
			name: "category_image_url",
			label: "Image",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div style={{width: 100, height: 100}}>
							<Image alt={value} src={value || no_image}/>
						</div>
					)
				}
			}
		},
		{
			name: "categoryIdentifierId",
			label: "Code",
			options: {
				display: true
			}
		},
		{
			name: "category_name",
			label: "Name",
			options: {
				display: true
			}
		},
		{
			name: "category_status",
			label: "Status",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowIndex: dataIndex} = tableMeta;
					const onChangeStatus = () => {
						changeCategoryStatus(tabPosition == 0 ? activeCategories[dataIndex]['categoryIdentifierId'] : inactiveCategories[dataIndex]['categoryIdentifierId'], (isUpdated) => {
							if (isUpdated) {
								if (value == 0 || value == 2) {
									updateValue("1");
								} else {
									updateValue("0");
								}
							}
						});
					}
					switch (value) {
						case "1" :
							return (
								<Button onClick={() => onChangeStatus()}>
									<Chip size="small" icon={<DoneIcon/>} label="Active"/>
								</Button>
							);
							break;
						case "0" :
							return (
								<Button onClick={() => onChangeStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						case "2" :
							return (
								<Button onClick={() => onChangeStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						default :
							return (null);
							break;
					}
				}
			}
		}
	];

	const brandHeadCell = [
		{
			name: "brand_id",
			label: "--",
			options: {
				display: false
			}
		},
		{
			name: "brand_image_url",
			label: "Image",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div style={{width: 100, height: 100}}>
							<Image alt={value} src={value || no_image}/>
						</div>
					)
				}
			}
		},
		{
			name: "brand_code",
			label: "Code",
			options: {
				display: true
			}
		},
		{
			name: "brand_name",
			label: "Name",
			options: {
				display: true
			}
		},
		{
			name: "brand_status",
			label: "Status",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					const {rowIndex: dataIndex} = tableMeta;
					const onChangeStatus = () => {
						changeBrandStatus(tabPosition == 0 ? activeCategories[dataIndex]['brand_code'] : inactiveCategories[dataIndex]['brand_code'], (isUpdated) => {
							if (isUpdated) {
								if (value == 0 || value == 2) {
									updateValue("1");
								} else {
									updateValue("0");
								}
							}
						});
					}
					switch (value) {
						case "1" :
							return (
								<Button onClick={() => onChangeStatus()}>
									<Chip size="small" icon={<DoneIcon/>} label="Active"/>
								</Button>
							);
							break;
						case "0" :
							return (
								<Button onClick={() => onChangeStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						case "2" :
							return (
								<Button onClick={() => onChangeStatus()}>
									<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
								</Button>
							);
							break;
						default :
							return (null);
							break;
					}
				}
			}
		}
	];

	const categoryMHeadCell = [
		{
			name: "category_id",
			label: "--",
			options: {
				display: false
			}
		},
		{
			name: "category_image_url",
			label: "Image",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div style={{width: 100, height: 100}}>
							<Image alt={value} src={value || no_image}/>
						</div>
					)
				}
			}
		},
		{
			name: "categoryIdentifierId",
			label: "Code",
			options: {
				display: true
			}
		},
		{
			name: "category_name",
			label: "Name",
			options: {
				display: true
			}
		},
		{
			name: "category_status",
			label: "Status",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					switch (value) {
						case "1" :
							return (
								<Chip size="small" icon={<DoneIcon/>} label="Active"/>
							);
							break;
						case "0" :
							return (
								<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
							);
							break;
						case "2" :
							return (
								<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
							);
							break;
						default :
							return (null);
							break;
					}
				}
			}
		}
	];

	const brandMHeadCell = [
		{
			name: "brand_id",
			label: "--",
			options: {
				display: false
			}
		},
		{
			name: "brand_image_url",
			label: "Image",
			options: {
				display: true,
				filter: false,
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<div style={{width: 100, height: 100}}>
							<Image alt={value} src={value || no_image}/>
						</div>
					)
				}
			}
		},
		{
			name: "brand_code",
			label: "Code",
			options: {
				display: true
			}
		},
		{
			name: "brand_name",
			label: "Name",
			options: {
				display: true
			}
		},
		{
			name: "brand_status",
			label: "Status",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					switch (value) {
						case "1" :
							return (
								<Chip size="small" icon={<DoneIcon/>} label="Active"/>
							);
							break;
						case "0" :
							return (
								<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
							);
							break;
						case "2" :
							return (
								<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>
							);
							break;
						default :
							return (null);
							break;
					}
				}
			}
		}
	];

	const getIndex = (item) => {
		const arr = searchResult.length === 0 ? stocks : searchResult;
		for (let i = 0; i < arr.length; i++) {
			const {product_code} = arr[i];
			if (item.product_code == product_code) {
				return i;
			}
		}
	}

	const onPressActiveStatusMenu = (index) =>{
		setItemStatusAnchor(null);
		setTabPosition(index);
	}

	const chosenStockData = searchResult.length === 0 ? stocks[chosenStock] : searchResult[chosenStock];
	const chosenCategoryData = categories[chosenMCategory];
	const chosenBrandData = brands[chosenMBrand];

	return (
		<div>
			{de("start", chosenCategoryId, chosenBrandId)}
			{
				currentPage === 0 && (
					noData ?
						<div style={{marginTop: 100}}>
							<NodataLottie/>
						</div>
						:
						<div>
							<div className={rowClass.viewInRowSpace}>
								{
									chosenCategory !== '' || chosenBrand !== '' ?
										<Breadcrumbs
											className={classes.left}
											separator={<NavigateNextIcon
												fontSize="small"/>}
											aria-label="breadcrumb">
											<Link
												color="inherit"
												onClick={() => onDefaultCategory()}>
												{chosenCategory !== '' ? language.category : language.brand}
											</Link>
											<Typography color="textPrimary">
												{chosenCategory !== '' ? chosenCategory : chosenBrand}
											</Typography>
										</Breadcrumbs> :
										categories.length > 0 && brands.length > 0 && searchResult.length === 0 ?
											<Typography variant="h6" component="p" className={classes.sectionTitle}>
												{/* { language.category } */}
											</Typography>
											: <div/>
								}
								<div className={rowClass.viewInRowSpace}>
									{
										integration === '0' && (
											<Button
												className={classes.marginRight}
												variant="outlined"
												color="secondary"
												onClick={() => setNewProductView(true)}
												startIcon={<AddIcon/>}>
												{language.new_product}
											</Button>
										)
									}
									<Button
										className={classes.marginRight}
										variant="outlined"
										color="secondary"
										aria-controls="active-status-menu"
										aria-haspopup="true"
										onClick={event=>setItemStatusAnchor(event.currentTarget)}>
										{tabPosition === 0 ? language.active : language.inactive} Items
									</Button>
									<Menu
										id="active-status-menu"
										anchorEl={itemStatusAnchor}
										keepMounted
										open={Boolean(itemStatusAnchor)}
										onClose={()=>setItemStatusAnchor(null)}>
										<MenuItem onClick={()=>onPressActiveStatusMenu(0)}>{language.active}</MenuItem>
										<MenuItem onClick={()=>onPressActiveStatusMenu(1)}>{language.inactive}</MenuItem>
									</Menu>
									<Button
										className={classes.marginRight}
										variant="outlined"
										color="secondary"
										onClick={() => changeTableView()}
										startIcon={stockViewInList ? <ListIcon/> : <ViewCompactIcon/>}>
										{stockViewInList ? language.listview : language.gridview}
									</Button>
									<SearchBox
										onChangeText={(e) => searchThrottle(e, axiosConfig)}
										text={language.search}
									/>
								</div>
							</div>
							{
								(!_.isEmpty(searchQuery) && _.isEmpty(searchResult)) ?
									<div key={'nosearchresult'} style={{marginTop: '10%'}}>
										<SearchLottie/>
									</div>
									:
									(!_.isEmpty(searchQuery) && !_.isEmpty(searchResult)) ?
										<div key={'searchResult'}>
											{(<>
												{/*<Tabs
													indicatorColor={'primary'}
													textColor={'inherit'}
													variant={'fullWidth'}
													value={tabPosition}
													centered
													onChange={(e, v) => setTabPosition(v)}
													aria-label="simple tabs log">
													<Tab label={language.active}/>
													<Tab label={language.inactive}/>
												</Tabs>*/}
												{
													searchResult.length > 0 && (
														<div style={{height: 15}}/>
													)
												}
												{
													searchResult.length > 0 && (
														<Typography variant="h6" component="p" className={classes.sectionTitle}>
															{language.search_result}
														</Typography>
													)
												}
												{
													searchResult.length > 0 && (
														<div style={{height: 5}}/>
													)
												}
												{
													tabPosition == 0 && activeSearchResult.length > 0 && (
														stockViewInList ?
															<ProductTable
																itemHeadCell={itemHeadCell}
																productList={activeSearchResult}
																onClickStockList={onClickStockList}
															/> :
															<GridList
																items={activeSearchResult}
																getGridGap={getGridGap}
																getColumnCount={getColumnCount}
																getWindowMargin={getWindowMargin}
																fixedColumnWidth={200}
																getItemData={(item, cwidth) => {
																	return {
																		key: item.product_code,
																		height: 430
																	}
																}}
																renderItem={(item) => {
																	return (
																		<StockColumnCard
																			item={item}
																			classes={classes}
																			onClickStock={() => onClickStock(getIndex(item))}
																			language={language}
																			changeProductStatus={changeProductStatus}
																			changeSLStatus={changeSLStatus}
																		/>
																	)
																}}
															/>
													)
												}
												{
													tabPosition == 1 && inActiveSearchResult.length > 0 && (
														stockViewInList ?
															<ProductTable
																itemHeadCell={itemHeadCell}
																productList={inActiveSearchResult}
																onClickStockList={onClickStockList}
															/> :
															<GridList
																items={inActiveSearchResult}
																getGridGap={getGridGap}
																getColumnCount={getColumnCount}
																getWindowMargin={getWindowMargin}
																fixedColumnWidth={200}
																getItemData={(item, cwidth) => {
																	return {
																		key: item.product_code,
																		height: 430
																	}
																}}
																renderItem={(item) => {
																	return (
																		<StockColumnCard
																			item={item}
																			classes={classes}
																			onClickStock={() => onClickStock(getIndex(item))}
																			language={language}
																			changeProductStatus={changeProductStatus}
																			changeSLStatus={changeSLStatus}
																		/>
																	)
																}}
															/>
													)
												}
											</>)}
										</div>
										:
										<div key={'default'}>
											{
												categories.length > 0 && chosenCategory == '' && (
													<div style={{height: 5}}/>
												)
											}
											{(<>
												{/*<Tabs
													indicatorColor={'primary'}
													textColor={'inherit'}
													variant={'fullWidth'}
													value={tabPosition}
													centered
													onChange={(e, v) => setTabPosition(v)}
													aria-label="simple tabs log">
													<Tab label={language.active}/>
													<Tab label={language.inactive}/>
												</Tabs>*/}
												{
													tabPosition == 0 && activeCategories.length > 0 && chosenCategory == '' && (
														<div style={{height: 15}}/>
													)
												}
												{
													tabPosition == 0 && activeCategories.length > 0 && chosenCategory == '' && chosenBrand == '' && (
														<Typography variant="h6" component="p" className={classes.sectionTitle}>
															{language.category}
														</Typography>
													)
												}
												{
													tabPosition == 0 && activeCategories.length > 0 && chosenCategory == '' && chosenBrand == '' && (
														stockViewInList ?
															<MUIDataTable
																title={undefined}
																data={activeCategories}
																columns={categoryHeadCell}
																options={{
																	print: false,
																	filter: false,
																	download: false,
																	search: false,
																	selectableRows: 'none',
																	rowsPerPage: 40,
																	rowsPerPageOptions: [25, 40, 65, 100],
																	responsive: 'scrollMaxHeight',
																	onCellClick: (colData, {colIndex, dataIndex, rowIndex}) => {
																		if (colIndex != (categoryHeadCell.length - 1)) {
																			onSelectCategory(activeCategories[dataIndex]['categoryIdentifierId'], activeCategories[dataIndex]['category_id']);
																		}
																	},
																	setTableProps: () => ({size: 'small'})
																}}
															/>
															:
															<GridList
																items={activeCategories}
																getGridGap={getGridGap}
																getColumnCount={getColumnCount}
																getWindowMargin={getWindowMargin}
																fixedColumnWidth={200}
																getItemData={(item, cwidth) => {
																	return {
																		key: item.category_id,
																		height: 370
																	}
																}}
																renderItem={(item) => {
																	return (
																		<CategoriesCard
																			item={item}
																			changeCategoryStatus={changeCategoryStatus}
																			language={language}
																			onSelectCategory={onSelectCategory}
																			classes={classes}
																			setCategoryForImage={setCategoryForImage}
																			toggleCategoryImageUpload={toggleCategoryImageUpload}
																			toggleCategoryImage_inDB={toggleCategoryImage_inDB}
																		/>
																	)
																}}
															/>
													)
												}
												{
													tabPosition == 0 && activeBrands.length > 0 && chosenCategory == '' && chosenBrand == '' && (
														<div style={{height: 15}}/>
													)
												}
												{
													tabPosition == 0 && activeBrands.length > 0 && chosenCategory == '' && chosenBrand == '' && (
														<Typography variant="h6" component="p" className={classes.sectionTitle}>
															{language.brand}
														</Typography>
													)
												}
												{
													tabPosition == 0 && activeBrands.length > 0 && chosenCategory == '' && chosenBrand == '' && (
														stockViewInList ?
															<MUIDataTable
																title={undefined}
																data={activeBrands}
																columns={brandHeadCell}
																options={{
																	print: false,
																	filter: false,
																	download: false,
																	search: false,
																	selectableRows: 'none',
																	rowsPerPage: 40,
																	rowsPerPageOptions: [25, 40, 65, 100],
																	responsive: 'scrollMaxHeight',
																	onCellClick: (colData, {colIndex, dataIndex, rowIndex}) => {
																		if (colIndex != (brandHeadCell.length - 1)) {
																			onSelectBrand(activeBrands[dataIndex]['brand_code'], activeBrands[dataIndex]['brand_id']);
																		}
																	},
																	setTableProps: () => ({size: 'small'})
																}}
															/>
															:
															<GridList
																items={activeBrands}
																getGridGap={getGridGap}
																getColumnCount={getColumnCount}
																getWindowMargin={getWindowMargin}
																fixedColumnWidth={200}
																getItemData={(item, cwidth) => {
																	return {
																		key: item.brand_id,
																		height: 370
																	}
																}}
																renderItem={(item) => {
																	return (
																		<BrandsCard
																			item={item}
																			changeBrandStatus={changeBrandStatus}
																			language={language}
																			onSelectBrand={onSelectBrand}
																			classes={classes}
																			setBrandForImage={setBrandForImage}
																			toggleBrandImageUpload={toggleBrandImageUpload}
																			toggleBrandImage_inDB={toggleBrandImage_inDB}
																		/>
																	)
																}}
															/>
													)
												}
												{
													tabPosition == 0 && stocks.length > 0 && (
														<div style={{height: 15}}/>
													)
												}
												{
													tabPosition == 0 && activeStocks.length > 0 && (
														<Typography variant="h6" component="p" className={classes.sectionTitle}>
															{language.items}
														</Typography>
													)
												}
												{
													tabPosition == 0 && activeStocks.length > 0 && (
														stockViewInList ?
															<ProductTable
																itemHeadCell={itemHeadCell}
																productList={activeStocks}
																onClickStockList={onClickStockList}
															/> :
															<GridList
																items={activeStocks}
																getGridGap={getGridGap}
																getColumnCount={getColumnCount}
																getWindowMargin={getWindowMargin}
																fixedColumnWidth={200}
																getItemData={(item, cwidth) => {
																	return {
																		key: item.product_code,
																		height: 430
																	}
																}}
																renderItem={(item) => {
																	return (
																		<StockColumnCard
																			item={item}
																			classes={classes}
																			onClickStock={() => onClickStock(getIndex(item))}
																			language={language}
																			changeProductStatus={changeProductStatus}
																			changeSLStatus={changeSLStatus}
																		/>
																	)
																}}
															/>
													)
												}
												{
													tabPosition == 1 && inactiveCategories.length > 0 && chosenCategory == '' && chosenBrand == '' && (
														<div style={{height: 15}}/>
													)
												}
												{
													tabPosition == 1 && inactiveCategories.length > 0 && chosenCategory == '' && chosenBrand == '' && (
														<Typography variant="h6" component="p" className={classes.sectionTitle}>
															{language.category}
														</Typography>
													)
												}
												{
													tabPosition == 1 && inactiveCategories.length > 0 && chosenCategory == '' && chosenBrand == '' && (
														stockViewInList ?
															<MUIDataTable
																title={undefined}
																data={inactiveCategories}
																columns={categoryHeadCell}
																options={{
																	print: false,
																	filter: false,
																	download: false,
																	search: false,
																	selectableRows: 'none',
																	rowsPerPage: 40,
																	rowsPerPageOptions: [25, 40, 65, 100],
																	responsive: 'scrollMaxHeight',
																	onCellClick: (colData, {colIndex, dataIndex, rowIndex}) => {
																		if (colIndex != (categoryHeadCell.length - 1)) {
																			onSelectCategory(inactiveCategories[dataIndex]['categoryIdentifierId'], inactiveCategories[dataIndex]['category_id']);
																		}
																	},
																	setTableProps: () => ({size: 'small'})
																}}
															/>
															:
															<GridList
																items={inactiveCategories}
																getGridGap={getGridGap}
																getColumnCount={getColumnCount}
																getWindowMargin={getWindowMargin}
																fixedColumnWidth={200}
																getItemData={(item, cwidth) => {
																	return {
																		key: item.category_id,
																		height: 370
																	}
																}}
																renderItem={(item) => {
																	return (
																		<CategoriesCard
																			item={item}
																			changeCategoryStatus={changeCategoryStatus}
																			language={language}
																			onSelectCategory={onSelectCategory}
																			classes={classes}
																			setCategoryForImage={setCategoryForImage}
																			toggleCategoryImageUpload={toggleCategoryImageUpload}
																			toggleCategoryImage_inDB={toggleCategoryImage_inDB}
																		/>
																	)
																}}
															/>
													)
												}
												{
													tabPosition == 1 && inactiveBrands.length > 0 && chosenCategory == '' && chosenBrand == '' && (
														<div style={{height: 15}}/>
													)
												}
												{
													tabPosition == 1 && inactiveBrands.length > 0 && chosenCategory == '' && chosenBrand == '' && (
														<Typography variant="h6" component="p" className={classes.sectionTitle}>
															{language.brand}
														</Typography>
													)
												}
												{
													tabPosition == 1 && inactiveBrands.length > 0 && chosenCategory == '' && chosenBrand == '' && (
														stockViewInList ?
															<MUIDataTable
																title={undefined}
																data={inactiveBrands}
																columns={brandHeadCell}
																options={{
																	print: false,
																	filter: false,
																	download: false,
																	search: false,
																	selectableRows: 'none',
																	rowsPerPage: 40,
																	rowsPerPageOptions: [25, 40, 65, 100],
																	responsive: 'scrollMaxHeight',
																	onCellClick: (colData, {colIndex, dataIndex, rowIndex}) => {
																		if (colIndex != (brandHeadCell.length - 1)) {
																			onSelectCategory(inactiveBrands[dataIndex]['brand_code'], inactiveBrands[dataIndex]['brand_id']);
																		}
																	},
																	setTableProps: () => ({size: 'small'})
																}}
															/>
															:
															<GridList
																items={inactiveBrands}
																getGridGap={getGridGap}
																getColumnCount={getColumnCount}
																getWindowMargin={getWindowMargin}
																fixedColumnWidth={200}
																getItemData={(item, cwidth) => {
																	return {
																		key: item.brand_id,
																		height: 370
																	}
																}}
																renderItem={(item) => {
																	return (
																		<BrandsCard
																			item={item}
																			changeBrandStatus={changeBrandStatus}
																			language={language}
																			onSelectBrand={onSelectBrand}
																			classes={classes}
																			setBrandForImage={setBrandForImage}
																			toggleBrandImageUpload={toggleBrandImageUpload}
																			toggleBrandImage_inDB={toggleBrandImage_inDB}
																		/>
																	)
																}}
															/>
													)
												}

												{
													tabPosition == 1 && inactiveStocks.length > 0 && (
														<div style={{height: 15}}/>
													)
												}
												{
													tabPosition == 1 && inactiveStocks.length > 0 && (
														<Typography variant="h6" component="p" className={classes.sectionTitle}>
															{language.items}
														</Typography>
													)
												}
												{
													tabPosition == 1 && inactiveStocks.length > 0 && (
														stockViewInList ?
															<ProductTable
																itemHeadCell={itemHeadCell}
																productList={inactiveStocks}
																onClickStockList={onClickStockList}
															/> :
															<GridList
																items={inactiveStocks}
																getGridGap={getGridGap}
																getColumnCount={getColumnCount}
																getWindowMargin={getWindowMargin}
																fixedColumnWidth={200}
																getItemData={(item, cwidth) => {
																	return {
																		key: item.product_code,
																		height: 370
																	}
																}}
																renderItem={(item) => {
																	return (
																		<StockColumnCard
																			item={item}
																			classes={classes}
																			onClickStock={() => onClickStock(getIndex(item))}
																			language={language}
																			changeProductStatus={changeProductStatus}
																			changeSLStatus={changeSLStatus}
																		/>
																	)
																}}
															/>
													)
												}
											</>)}
										</div>
							}
						</div>
				)
			}
			{
				currentPage === 1 && (
					noData ?
						<div style={{marginTop: 100}}>
							<NodataLottie/>
						</div>
						:
						<div>
							{(<>
								<Tabs
									indicatorColor={'primary'}
									textColor={'inherit'}
									variant={'fullWidth'}
									value={mTabPosition}
									centered
									onChange={(e, v) => setMTabPosition(v)}
									aria-label="simple tabs log">
									<Tab label={language.category}/>
									<Tab label={language.brand}/>
								</Tabs>
								<div style={{height: 15}}/>
								<MUIDataTable
									title={mTabPosition == 0 ? language.category_list : language.brand_list}
									data={mTabPosition == 0 ? categories : brands}
									columns={mTabPosition == 0 ? categoryMHeadCell : brandMHeadCell}
									options={{
										print: false,
										filter: true,
										download: false,
										selectableRows: 'none',
										rowsPerPage: 99,
										rowsPerPageOptions: [125, 140, 165, 200],
										responsive: 'scroll',
										onRowClick: (rowData, {dataIndex}) => {
											mTabPosition == 0 ?
												onClickCategoryList(categories[dataIndex]['categoryIdentifierId'], categories) : onClickBrandList(brands[dataIndex]['brand_code'], brands)
										},
										customToolbar: () =>
											<TemplateTableCustomToolbar
												addNewItem={() => {
													mTabPosition == 0 ? setNewCategoryView(true) : setNewBrandView(true)
												}}
											/>
									}}
								/>
							</>)}
						</div>
				)
			}
			<div style={{height: 50}}/>
			<ItemDetails
				open={showStockDetails}
				setClose={() => {
					setChosenStock(() => (0));
					toggleStockDetails(() => (false));
					// de("ItemDetails", chosenCategoryId, chosenBrandId);
					if (chosenCategoryId == -1 && chosenBrandId == -1) {
						loadStocks();
					} else {
						if (chosenBrandId == -1) {
							loadStocks(chosenCategoryId, -1);
						}
						if (chosenCategoryId == -1) {
							loadStocks(-1, chosenBrandId);
						}
					}
				}}
				item={showStockDetails ? chosenStockData : null}
				language={language}
				onUpdateStock={(newItem) => {
					onUpdateStock(newItem)
				}}
				onSnack={(msg, type) => snack(msg, type)}
				integration={integration}
				categories={activeCategories}
				brands={activeBrands}
			/>
			<Backdrop className={classes.backdrop} open={backdrop} onClick={() => {
			}}>
				<CircularProgress color="inherit"/>
			</Backdrop>
			<SingleUpload
				openModal={openCategoryImageUpload}
				onChange={props => onCategoryImageUpload(props)}
				onClose={() => toggleCategoryImageUpload(false)}
				dropzoneStyle={classes.dropzoneText}
				language={language}
				filesLimit={1}
				title={categoryForImage ? categoryForImage.category_name : ''}
				onSave={() => toggleCategoryImage_inDB(categoryForImage, false)}
			/>
			<SingleUpload
				openModal={openBrandImageUpload}
				onChange={props => onBrandImageUpload(props)}
				onClose={() => toggleBrandImageUpload(false)}
				dropzoneStyle={classes.dropzoneText}
				language={language}
				filesLimit={1}
				title={brandForImage ? brandForImage.brand_name : ''}
				onSave={() => toggleBrandImage_inDB(brandForImage, false)}
			/>
			<BottomNavigation
				value={currentPage}
				onChange={(event, newValue) => {
					setCurrentPage(newValue);
				}}
				styles={{
					width: '100%',
					position: 'fixed',
					bottom: 0
				}}
				showLabels
				className={commonStyles.bottomNav}>
				<BottomNavigationAction label="Stock List" icon={<CreateNewFolderIcon/>}/>
				<BottomNavigationAction label="Management" icon={<AccountTreeIcon/>}/>
				{/* <BottomNavigationAction label="Promo / Bundle" icon={<RedeemIcon />} /> */}
			</BottomNavigation>
			{
				newProductView && (
					<AddProduct
						open={newProductView}
						onClose={(data) => {
							setNewProductView(false);
							onUpdateStock(data);
						}}
						language={language}
						onSnack={(msg, type) => snack(msg, type)}
						axiosConfig={axiosConfig}
						category_list={activeCategories}
						brand_list={activeBrands}
					/>
				)
			}
			{
				newCategoryView && (
					<AddCategory
						open={newCategoryView}
						onClose={(data) => {
							setNewCategoryView(false);
							onUpdateCategory(data);
						}}
						language={language}
						onSnack={(msg, type) => snack(msg, type)}
						axiosConfig={axiosConfig}
					/>
				)
			}
			{
				(
					newBrandView && <AddBrand
						open={newBrandView}
						onClose={(data) => {
							setNewBrandView(false);
							onUpdateBrand(data);
						}}
						language={language}
						onSnack={(msg, type) => snack(msg, type)}
						axiosConfig={axiosConfig}
					/>
				)
			}
			{
				showCategoryDetails ?
					<CategoryDetails
						open={showCategoryDetails}
						setClose={() => {
							setChosenMCategory(() => (-1));
							toggleCategoryDetails(() => (false));
						}}
						item={showCategoryDetails ? chosenCategoryData : null}
						language={language}
						onUpdateCategory={(newItem) => {
							onUpdateCategory(newItem)
						}}
						onSnack={(msg, type) => snack(msg, type)}
						integration={integration}
					/> : null
			}
			{
				showBrandDetails ?
					<BrandDetails
						open={showBrandDetails}
						setClose={() => {
							setChosenMBrand(() => (-1));
							toggleBrandDetails(() => (false));
						}}
						item={showBrandDetails ? chosenBrandData : null}
						language={language}
						onUpdateBrand={(newItem) => {
							onUpdateBrand(newItem)
						}}
						onSnack={(msg, type) => snack(msg, type)}
						integration={integration}
					/> : null
			}
		</div>
	)
}


function CategoriesCard({
							item,
							changeCategoryStatus,
							language,
							onSelectCategory,
							classes,
							setCategoryForImage,
							toggleCategoryImageUpload,
							toggleCategoryImage_inDB
						}) {
	const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
	const [categoryStatus, setCategoryStatus] = useState(parseInt(item.category_status));
	const onChangeStatus = () => {
		changeCategoryStatus(item.categoryIdentifierId, (isUpdated) => {
			if (isUpdated) {
				const newStatus = categoryStatus ? 0 : 1;
				setCategoryStatus(newStatus);
				forceUpdate();
			}
		});
	}
	return (
		<Card className={classes.card}>
			<CardActionArea onClick={() => onSelectCategory(item.category_name, item.category_id)}
							style={{height: 300}}>
				<Image style={{ objectFit: 'contain' }} src={item.category_image_url || no_image}/>
				<CardContent>
					<Typography variant="subtitle1" component="h2">
						{item.category_name}
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Tooltip title={language.category_img}>
					<IconButton onClick={() => {
						setCategoryForImage(item);
						toggleCategoryImageUpload(true);
					}}>
						<PhotoLibraryIcon color={'primary'}/>
					</IconButton>
				</Tooltip>
				{
					JString(item.category_image_url).isValid() ?
						<Tooltip title={language.remove_cat_image}>
							<IconButton
								onClick={() => {
									toggleCategoryImage_inDB(item, true);
								}}>
								<DeleteForeverIcon color={'error'}/>
							</IconButton>
						</Tooltip>
						: null
				}
				<Tooltip title={`${language.change_status}`}>
					<IconButton
						onClick={() => {
							onChangeStatus();
						}}>
						{
							categoryStatus ?
								<LockOpenIcon/>
								:
								<LockIcon htmlColor={red[500]}/>
						}
					</IconButton>
				</Tooltip>
			</CardActions>
		</Card>
	)
}

function BrandsCard({
						item,
						changeBrandStatus,
						language,
						onSelectBrand,
						classes,
						setBrandForImage,
						toggleBrandImageUpload,
						toggleBrandImage_inDB
					}) {
	const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
	const [brandStatus, setBrandStatus] = useState(parseInt(item.brand_status));
	const onChangeStatus = () => {
		changeBrandStatus(item.brand_code, (isUpdated) => {
			if (isUpdated) {
				const newStatus = brandStatus ? 0 : 1;
				setBrandStatus(newStatus);
				forceUpdate();
			}
		});
	}
	return (
		<Card className={classes.card}>
			<CardActionArea onClick={() => onSelectBrand(item.brand_name, item.brand_id)} style={{height: 300}}>
				<Image style={{ objectFit: 'contain' }} src={item.brand_image_url || no_image}/>
				<CardContent>
					<Typography variant="subtitle1" component="h2">
						{item.brand_name}
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Tooltip title={language.brand_img}>
					<IconButton onClick={() => {
						setBrandForImage(item);
						toggleBrandImageUpload(true);
					}}>
						<PhotoLibraryIcon color={'primary'}/>
					</IconButton>
				</Tooltip>
				{
					JString(item.brand_image_url).isValid() ?
						<Tooltip title={language.remove_brand_image}>
							<IconButton
								onClick={() => {
									toggleBrandImage_inDB(item, true);
								}}>
								<DeleteForeverIcon color={'error'}/>
							</IconButton>
						</Tooltip>
						: null
				}
				<Tooltip title={`${language.change_status}`}>
					<IconButton
						onClick={() => {
							onChangeStatus();
						}}>
						{
							brandStatus ?
								<LockOpenIcon/>
								:
								<LockIcon htmlColor={red[500]}/>
						}
					</IconButton>
				</Tooltip>
			</CardActions>
		</Card>
	)
}

function StockColumnCard({item, changeProductStatus, classes, onClickStock, language, changeSLStatus}) {
	const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
	const [productStatus, setProductStatus] = useState(parseInt(item.product_status));
	const [stockLevelStatus, setStockLevelStatus] = useState(parseInt(item.is_stock_level));
	const onChangeStatus = () => {
		changeProductStatus(item.product_code, (isUpdated) => {
			if (isUpdated) {
				const newStatus = productStatus ? 0 : 1;
				setProductStatus(newStatus);
				forceUpdate();
			}
		});
	}
	const onChangeSLStatus = () => {
		changeSLStatus(item.product_code, (isUpdated) => {
			if (isUpdated) {
				const newStatus = stockLevelStatus ? 0 : 1;
				setStockLevelStatus(newStatus);
			}
		});
	}
	return (
		<Card
			className={classes.card}>
			<CardActionArea onClick={() => onClickStock()} style={{height: 350}}>
				<Image style={{ objectFit: 'contain' }} src={item.image_url || no_image}/>
				<CardContent>
					<Typography variant="inherit" component="p" className={classes.fontGrey}>
						{item.product_code}
					</Typography>
					<Typography variant="inherit" component="h4">
						{item.product_name}
					</Typography>
					<Typography variant="inherit" component="p">
						{JString(item.product_desc).trimHtml()}
					</Typography>
					{
						item.category_name && (
							<Typography variant="inherit" component="p">
								Category: {JString(item.category_name).trimHtml()}
							</Typography>
						)
					}
					{
						item.brand_name && (
							<Typography variant="inherit" component="p">
								Brand: {JString(item.brand_name).trimHtml()}
							</Typography>
						)
					}
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Tooltip title={language.change_status}>
					<IconButton
						onClick={() => {
							onChangeStatus();
						}}>
						{
							productStatus ?
								<LockOpenIcon/>
								:
								<LockIcon htmlColor={red[500]}/>
						}
					</IconButton>
				</Tooltip>
				<Tooltip title={language.stock_level_tooltip}>
					<IconButton
						onClick={() => {
							onChangeSLStatus();
						}}>
						{
							stockLevelStatus ?
								<Layers htmlColor={green[500]}/>
								:
								<LayersClear htmlColor={red[500]}/>
						}
					</IconButton>
				</Tooltip>
			</CardActions>
		</Card>
	)
}

function ToolbarRefreshButton({onClick}) {
	return (
		<React.Fragment>
			<Tooltip title={"Refresh data"}>
				<IconButton onClick={() => onClick()}>
					<SyncIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}

function getGridGap(elementWidth, windowHeight) {
	if (elementWidth > 720 && windowHeight > 480) {
		return 15
	} else {
		return 10
	}
}

function getColumnCount(elementWidth, gridGap) {
	if (elementWidth > 720) {
		return 6
	} else {
		return 2
	}
}

function getWindowMargin(windowHeight) {
	return Math.round(windowHeight * 1.5)
}

const ProductTable = React.memo(({itemHeadCell, productList, onClickStockList}) => {
	if (productList.length == 0) {
		return null;
	}
	return (
		<MUIDataTable
			title={undefined}
			data={productList}
			columns={itemHeadCell}
			options={{
				print: false,
				filter: false,
				download: false,
				search: false,
				selectableRows: 'none',
				rowsPerPage: 40,
				rowsPerPageOptions: [25, 40, 65, 100],
				responsive: 'scrollMaxHeight',
				onCellClick: (colData, {colIndex, dataIndex, rowIndex}) => {
					if (colIndex != (itemHeadCell.length - 2)) {
						onClickStockList(productList[dataIndex]['product_code'], productList);
					}
				},
				setTableProps: () => ({size: 'small'})
			}}
		/>
	)
}, areEqual);

function areEqual(prevProps, nextProps) {
	return prevProps.productList.length === nextProps.productList.length;
}

function TemplateTableCustomToolbar({addNewItem}) {
	return (
		<React.Fragment>
			<Tooltip title={'Add New Item'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => addNewItem()} aria-label="addnew">
					<AddIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}

export function LinkTab(props) {
	return (
		<Tab
			component="a"
			onClick={(event) => {
				event.preventDefault();
			}}
			{...props}
		/>
	);
}
